  <template>
  <custom-dialog
    :show="show"
    :title="$t('label.postSet.page_title')"
    width="720"
    @toggleShow="handleClose"
  >
    <div class="flex form maxHigh" slot="main">
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        :rules="rules"
        :label-position="
          lang == 'ru-RU' || lang == 'tr-TR' || lang == 'es' ? 'top' : 'left'
        "
        label-width="100px"
      >
        <el-row :gutter="10" style="max-height: 400px">
          <el-col :span="24">
            <h3 style="margin-bottom: 10px">数据链路配置</h3>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('dialog.postSet.data_link') + ':'">
              <el-input disabled size="small" v-model="form.data_link">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('dialog.postSet.transmit_type') + ':'">
              <el-select
                class="select_input"
                v-model="form.transmit_type"
                size="small"
                style="width: 100%"
                :placeholder="$t('dialog.postSet.transmit_type')"
                @change="transmitChange"
              >
                <el-option
                  v-for="option in $t('option.postSet.transmit_type_options')"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-for="item in currentFormItem" :key="item.key">
            <el-form-item :label="$t(item.label) + '：'" :prop="item.key">
              <template v-if="item.inputType == 'select'">
                <template v-if="item.key == 'output_format'">
                  <el-select
                    v-model="form[item.key]"
                    size="small"
                    style="width: 100%"
                    :placeholder="$t(item.label)"
                  >
                    <el-option
                      v-for="option in outputOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-else>
                  <el-select
                    v-model="form[item.key]"
                    size="small"
                    style="width: 100%"
                    :placeholder="$t(item.label)"
                  >
                    <el-option
                      v-for="option in $t(item.selectKey)"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </template>
              <template v-else-if="item.inputType == 'input'">
                <template v-if="item.key == 'mount_point'">
                  <div class="flex" style="max-width: 200px">
                    <el-input
                      size="small"
                      style="flex: 1"
                      v-model="form[item.key]"
                      :placeholder="$t(item.label)"
                    ></el-input>
                  </div>
                </template>
                <template v-else>
                  <el-input
                    :show-password="item.key == 'password'"
                    :maxlength="
                      item.key == 'IP' ||
                      item.key == 'password' ||
                      item.key == 'username'
                        ? '15'
                        : '8'
                    "
                    size="small"
                    v-model="form[item.key]"
                    :placeholder="$t(item.label)"
                  ></el-input>
                </template>
              </template>
            </el-form-item>
          </el-col>

          <!-- </transition> -->
        </el-row>
        <el-row>
          <el-col
            :span="24"
            v-if="
              checkLabel.length &&
              form.transmit_type == '3' &&
              form.output_format == '1'
            "
          >
            <h3 style="margin-bottom: 10px">
              {{ $t("label.postSet.dataList") }}
            </h3>
          </el-col>
          <el-form-item
            v-if="form.transmit_type == '3' && form.output_format == '1'"
            style="margin-bottom: 0px"
          >
            <el-checkbox-group style="padding: 0 10px" v-model="checkList">
              <el-checkbox
                v-for="(item, index) in checkLabel"
                :key="index"
                :label="item"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <!-- <transition name="ggaEnter"> -->
          <div
            class="gga"
            v-if="
              checkLabel.length &&
              checkList.includes('GGA') &&
              form.transmit_type == '3' &&
              form.output_format == '1'
            "
          >
            <el-row>
              <el-form-item
                :label="$t('label.postSet.SN')"
                style="margin-bottom: 0px"
                prop="sn"
              >
                <el-switch v-model="form.sn" active-color="#659b5d">
                </el-switch>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('label.postSet.GGA')"
                style="margin-bottom: 8px"
                prop="gga"
              >
                <el-switch v-model="form.gga" active-color="#659b5d">
                </el-switch>
              </el-form-item>
            </el-row>

            <template v-if="form.gga">
              <el-row>
                <el-form-item
                  :label="$t('label.postSet.float')"
                  style="margin-bottom: 8px"
                  prop="interval"
                >
                  <el-input
                    size="mini"
                    :placeholder="$t('label.postSet.float')"
                    v-model="form.interval"
                  >
                    <template slot="append">S</template>
                  </el-input>
                </el-form-item>
              </el-row>
              <!-- <el-row>
                <el-form-item
                  :label="$t('label.postSet.fixed')"
                  style="margin-bottom: 8px"
                  prop="fixed"
                >
                  <el-switch v-model="form.fixed" active-color="#659b5d">
                  </el-switch>
                </el-form-item>
              </el-row> -->
            </template>
          </div>
        </el-row>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="save">
        {{ $t("dialog.userList.dispose") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import { receiverNetConfig, getNMEA } from "@/api/index";
import {
  validatePort,
  validateUserNamePWD,
  validateJRD,
  validateInterval,
  validateIP,
} from "../../../utils/regExp";
export default {
  name: "EditPost",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      checkList: [],
      checkLabel: [
        // "GGA",
        // "GSA",
        // "GST",
        // "GSV",
        // "RMC",
        //
        // "GLL",
        // "VTG",
        // "ZDA",
        // "DTM",
        // "GBS",
        // "GNS",
        // "GRS",
        // "THS",
        // "ROC",
      ],
      formItem: [
        // {
        //   label: "dialog.postSet.remote_ip",
        //   key: "remote_ip",
        //   inputType: "input",
        //   transmit_type_query: ["1"],
        // },
        {
          label: "dialog.postSet.IP",
          key: "IP",
          inputType: "input",
          transmit_type_query: ["1", "2", "3"],
        },
        {
          label: "dialog.postSet.post",
          key: "post",
          inputType: "input",
          transmit_type_query: ["2", "1", "3"],
        },

        {
          label: "dialog.postSet.username",
          key: "username",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.password",
          key: "password",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.mount_point",
          key: "mount_point",
          inputType: "input",
          transmit_type_query: ["2", "3"],
        },
        {
          label: "dialog.postSet.output_format",
          key: "output_format",
          inputType: "select",
          selectKey: "option.postSet.output_format_options",
          transmit_type_query: ["1", "3"],
        },
      ],
      form: {
        data_link: "",
        transmit_type: "",
        remote_ip: "",
        IP: "",
        post: "",
        username: "",
        password: "",
        mount_point: "",
        output_format: "",
        version: "",
        gga: false, // GGA平滑
        sn: false, // SN前缀
        fixed: false, // 剔除非固定解
        interval: "60",
        inputPointValue: "",
      },
      inputValue: "",
      rules: {
        IP: [
          {
            required: true,
            message: this.$t("dialog.postSet.ip"),
            trigger: "blur",
          },
          { validator: validateIP, trigger: "blur" },
        ],
        post: [
          { required: true, validator: validatePort, trigger: "blur" },
          { max: 8, message: this.$t("dialog.postSet.port"), trigger: "blur" },
        ],
        username: [
          { validator: validateUserNamePWD, trigger: "blur" },
          {
            max: 15,
            message: this.$t("dialog.postSet.nameLength"),
            trigger: "blur",
          },
        ],
        password: [
          { validator: validateUserNamePWD, trigger: "blur" },
          {
            max: 15,
            message: this.$t("dialog.postSet.pwdLength"),
            trigger: "blur",
          },
        ],
        mount_point: [
          { validator: validateJRD, trigger: "blur" },
          {
            max: 15,
            message: this.$t("dialog.postSet.point"),
            trigger: "blur",
          },
        ],
        interval: [
          { validator: validateInterval, trigger: "blur" },
          {
            max: 15,
            message: this.$t("dialog.postSet.interval"),
            trigger: "blur",
          },
        ],
      },
      options: [],
      NMEAList: {}
      // inputPointValue:"", // 接入点设置
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.form.transmit_type == "3") {
        return this.formItem.filter(
          (f) =>
            f.key != "username" && f.key != "password" && f.key != "mount_point"
        );
      } else {
        return this.formItem;
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
    outputOptions() {
      // return this.$t("option.postSet.output_format_options");
      if (this.form.transmit_type == "2") {
        this.form.output_format = "2"
        return this.$t("option.postSet.output_format_options").filter((f) => {
          return f.value == "2";
        });
      } else {
        // this.form.output_format = "1"
        return this.$t("option.postSet.output_format_options");
      }
    },
  },
  watch: {
    rowInfo: {
      handler(val) {
        console.log(val, "rowInfo");
        if (val) {
          this.initNMEA();
          this.form = {
            ...this.$tool.clone(val),
            IP: val.ip,
            data_link: val.data_link == 1 ? "内置网络" : "内置网络", // 暂时只有内置网络
            post: val.post,
            output_format: val.output != 65535 && val.output != 0 ? val.output : "",
            transmit_type: val.name != 65535 ? val.name + "" : "",
            username: val.account,
            password: val.password,
            mount_point: val.access_point,
            gga: val.smooth_switch == 1 ? true : false,
            interval: val.smooth_interval + "",
            fixed: val.rm_float == 1 ? true : false,
            sn: val.prefix_sn == 1 ? true : false,
          };
          this.checkList = []; //
          let arr = [
            "nmea_gga",
            "nmea_gsa",
            "nmea_gst",
            "nmea_gsv",
            "nmea_rmc",
            "nmea_zda",
            "nmea_vtg",
            "nmea_gll",
          ];
          arr.map((item, index) => {
            // console.log(item,val, val['nmea_zda'], index, "为什么呢")
            if (val[item] > 0) {
              // console.log(val[item], "2222222");
              this.checkList.push(item.split("_")[1].toUpperCase());
              console.log(this.checkList, "checkList");
            }
          });
          // console.log(this.form);
        } else {
          this.form = {
            name: "",
            contact: "",
            account: "",
            accountType: "",
            dealer: "",
            // output_format:"2"
          };
        }
      },
      deep: true,
    },
    // "form.transmit_type": {
    //   handler(val) {
    //     if(val == '2'){
    //       this.form.output_format = "0"
    //     } else {
    //       this.form.output_format = "1"
    //     }
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
  activated() {},
  methods: {
    initNMEA() {
      this.NMEAList = {}
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 2,
      };
      // 获取NMEA初始数据
      getNMEA(param).then((res) => {
        if (res.code == 200) {
          console.log(res.data, "data")
          this.checkLabel = Object.keys(res.data).reduce((total, item) => {
            if (res.data[item] !== 0) {
              total.push(item.toUpperCase());
            }
            this.NMEAList[item.toUpperCase()] = res.data[item]
            return total;
          }, []);
          console.log(this.NMEAList, "nmeaaaa")
        }
      });
    },
    handleClose(done) {
      this.$emit("toggleShow", false);
      this.checkList = [];
      this.form = {
        data_link: "",
        transmit_type: "",
        remote_ip: "",
        IP: "",
        post: "",
        username: "",
        password: "",
        mount_point: "",
        output_format: "",
        version: "",
        gga: false, // GGA平滑
        sn: false, // SN前缀
        fixed: false, // 剔除非固定解
        interval: "60",
        inputPointValue: "",
      };
    },
    transmitChange(e) {
      if (e == "2") {
        this.form.output_format = "2";
      } else {
        // this.form.output_format = "1"
      }
    },
    save() {
      // console.log(this.form, "formmmmmmm");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let param = {
            sn: sessionStorage.getItem("currentSn"),
            ip_syn: this.rowInfo.ip_syn,
            // data_link: this.form.data_link,
            data_link: 1,
            connect_mode: this.form.transmit_type,
            ip_address: this.form.IP,
            port: this.form.post,
            account: this.form.username,
            password: this.form.password,
            access_point: this.form.mount_point,
            output_mode: this.form.output_format ? this.form.output_format : 2,
            smooth_switch: this.form.gga ? 1 : 0,
            smooth_interval: this.form.gga ? this.form.interval : "0",
            // rm_float: this.form.fixed ? 1 : 0,
            rm_float: this.form.gga ? 1 : 0, // GGA平滑开启后默认剔除非固定解也是开启状态
            prefix_sn: this.form.sn ? 1 : 0,
            nmea_gga: this.checkList.includes("GGA") ? this.NMEAList["GGA"] : null,
            nmea_gsa: this.checkList.includes("GSA") ? this.NMEAList["GSA"] : null,
            nmea_gsv: this.checkList.includes("GSV") ? this.NMEAList["GSV"] : null,
            nmea_rmc: this.checkList.includes("RMC") ? this.NMEAList["RMC"] : null,
            nmea_gst: this.checkList.includes("GST") ? this.NMEAList["GST"] : null,
            nmea_zda: this.checkList.includes("ZDA") ? this.NMEAList["ZDA"] : null,
            nmea_vtg: this.checkList.includes("VTG") ? this.NMEAList["VTG"] : null,
            nmea_gll: this.checkList.includes("GLL") ? this.NMEAList["GLL"] : null,
          };
          console.log(param, "paramsaaaa");
          receiverNetConfig(param).then((res) => {
            // console.log(res);
            if (res.code === 200) {
              this.$message({
                // message: "指令下发成功！",
                message: this.$t("dialog.userList.edit_io_success"),
                type: "success",
                duration: 2000,
              });
              // if (this.rowInfo.status) {
              //   return;
              // } else {
              //   this.$emit("refush");
              // //   let refush = new Promise(resolve=>{
              // //       this.$emit("refush");
              // //       return resolve(true)
              // //   })
              // //  refush.then(res=>{
              // //       this.$emit('start', this.form)
              // //  })
              //   // console.log("kaishi");
              // }
              this.handleClose();
              this.$emit("refush");
            } else {
              // this.$message.error('网络错误，请重新保存！');
              this.$message({
                message: res.message,
                type: "error",
                duration: 2000,
              });
            }
          });
          // this.$refs["form"].resetFields();
        } else {
          // debugger
          // this.$refs['form'].resetFields();
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
.form {
  // overflow-x: hidden;
  // max-height: 420px;
  justify-content: center;
  overflow-x: hidden;
  .gga {
    padding: 0 10px;
  }
  .pd5 {
    padding: 0 0 10px;
  }
}
::v-deep {
  .el-checkbox__inner:hover {
    border-color: #659b5d;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #659b5d;
    border-color: #659b5d;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #659b5d;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #659b5d;
  }
}
// .select_input{
// 	::v-deep{
// 		.el-input__suffix{
// 			display: none;
// 		}
// 	}
// }
</style>