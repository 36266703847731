<template>
  <custom-dialog
    :show="show"
    :title="title"
    @toggleShow="handleClose"
    width="520"
  >
    <div class="flex form" slot="main">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        inline
        size="small"
        :label-width="$store.getters.deviceInfo.type != 'PC' ? 'auto' : '120px'"
        :label-position="
          lang == 'ru-RU' || lang == 'tr-TR' || lang == 'es' ? 'top' : 'right'
        "
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <template
            v-if="
              item.inputType == 'select' &&
              item.selectKey == 'option.userList.user_type_options'
            "
          >
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
              @change="selectChange"
            >
              <el-option
                v-for="option in $t(item.selectKey)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </template>
          <template
            v-else-if="
              item.inputType == 'select' &&
              item.selectKey == 'option.userList.dealer_options'
            "
          >
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
              @change="selectChange2"
            >
              <el-option
                v-for="option in options"
                :key="option.id"
                :label="option.account"
                :value="option.id"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.inputType == 'cascader'">
            <el-cascader
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
              v-model="form[item.key]"
              :options="belong_options"
              @change="testCHange"
            >
            </el-cascader>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                  maxlength="10"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button type="primary" class="btn save" size="small">
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-input
                size="small"
                :show-password="item.inputModel == 'pwd'"
                @input="change($event)"
                :maxlength="
                  item.inputModel == 'pwd' || item.inputModel == 'tel'
                    ? '15'
                    : '10'
                "
                style="width: 268px"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
      <el-button
        type="primary"
        class="btn save"
        size="small"
        @click="submitForm"
      >
        {{ $t("dialog.userList.submit") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import {
  userAdd,
  checkAccount,
  checkAgencyList,
  getLocation,
  editInfo,
} from "@/api/index";
export default {
  name: "EditUser",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    // 校验密码
    var validatePass = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      console.log(value, "value");
      if (!value) {
        callback(new Error(this.$t("dialog.userList.enterPwd")));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error(this.$t("dialog.userList.numAndLetter")));
      } else {
        if (this.form.repassword !== "") {
          this.$refs.form.validateField("repassword");
        }
        callback();
      }
    };
    // 校验确认密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("dialog.userList.surePwd")));
      } else if (value !== this.form.password) {
        callback(new Error(this.$t("dialog.userList.notSame")));
      } else {
        callback();
      }
    };
    // 校验手机号
    var validatePhone = (rule, value, callback) => {
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (!re.test(value)) {
        callback(new Error(this.$t("dialog.userList.phone")));
      } else {
        callback();
      }
    };
    // 用户名称
    var validateName = (rule, value, callback) => {
      var pattern = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error(this.$t("dialog.userList.enterName")));
      } else if (pattern.test(value)) {
        callback(new Error(this.$t("dialog.userList.nAndLAndC")));
      } else {
        callback();
      }
    };
    // 校验账号
    var validateAccount = (rule, value, callback) => {
      let sz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      // console.log(this.form)
      // var sz = /\d/;
      var pattern = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error(this.$t("dialog.userList.enterAccount")));
      } else if (sz.test(value) || pattern.test(value)) {
        callback(new Error(this.$t("dialog.userList.numAndLetterAnd_")));
      } else {
        let param = {
          account: this.form.account,
        };
        checkAccount(param).then((res) => {
          if (!res.data.exist) {
            callback();
          } else {
            callback(new Error(this.$t("dialog.userList.exist")));
          }
        });
      }
    };
    return {
      formItem: [
        {
          label: "dialog.userList.user_type",
          key: "accountType",
          inputType: "select",
          selectKey: "option.userList.user_type_options",
        },
        {
          label: "dialog.userList.user_name",
          key: "name",
          inputType: "input",
        },
        {
          label: "dialog.userList.account",
          key: "account",
          inputType: "input",
        },
        {
          label: "dialog.userList.password",
          key: "password",
          inputType: "input",
          inputModel: "pwd",
        },
        {
          label: "dialog.userList.repassword",
          key: "repassword",
          inputType: "input",
          inputModel: "pwd",
        },
        {
          label: "dialog.userList.belong",
          key: "belong",
          inputType: "cascader",
          inputModel: "cascader",
        },
        {
          label: "dialog.userList.contact",
          key: "contact",
          inputType: "input",
          inputModel: "tel",
        },
        {
          label: "dialog.userList.dealer",
          key: "dealer",
          inputType: "select",
          selectKey: "option.userList.dealer_options",
        },
      ],
      belong_options: [{
        label: "中国",
        value: "中国",
        key: "0",
        children: []
      }, {
        label: "国外",
        value: "国外",
        key: "1",
        children: []
      }], // 归属地下拉
      form: {
        name: "",
        contact: "",
        account: "",
        accountType: 1,
        dealer: "",
        password: "",
        repassword: "",
        belong: [],
      },
      rules: {
        accountType: [
          { required: true, message: this.$t("dialog.userList.userType"), trigger: "change" },
        ],
        name: [
          { required: true, validator: validateName, trigger: "blur" }
        ],
        account: [
          { required: true, validator: validateAccount, trigger: "blur" },
        ],
        password: [
          // { required: true, validator: validatePass, trigger: "blur" },
          {
            required: true,
            min: 6,
            message: this.$t("dialog.userList.pwdLendth6"),
            trigger: "blur",
          },
          // { required: true, max:15,message: '密码最大长度为15位', trigger: 'blur'}
        ],
        repassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        contact: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        dealer: [
          { required: true, message: this.$t("dialog.userList.delear"), trigger: "change" },
        ],
        belong: [
          { required: true, message: this.$t("dialog.userList.chooseBelong"), trigger: "change" }
        ],
      },
      options: [],
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      // console.log(JSON.parse(localStorage.getItem("userInfo")).account, "infoooooooo")
      if (
        this.rowInfo &&
        this.rowInfo.account !==
          JSON.parse(localStorage.getItem("userInfo")).account &&
        JSON.parse(localStorage.getItem("userInfo")).type == 2
      ) {
        return this.formItem.filter(
          (f) =>
            f.key == "name" ||
            f.key == "belong" ||
            f.key == "contact" ||
            f.key == "dealer"
          // (f) => f.key == "name" || f.key == "contact" || f.key == "dealer"
        );
      } else if (
        this.rowInfo &&
        this.rowInfo.id &&
        this.rowInfo.account ==
          JSON.parse(localStorage.getItem("userInfo")).account
      ) {
        return this.formItem.filter(
          (f) =>
            f.key == "accountType" || f.key == "belong" || f.key == "contact"
          // (f) => f.key == "name" || f.key == "contact"
        );
      } else {
        // if(this.form.accountType == 'dealer'){
        // 	return this.formItem.filter(
        // 		(f) => f.key != "dealer"
        // 	);
        // }else{
        // 	return this.formItem;
        // }
        return this.formItem;
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    rowInfo: {
      handler(val) {
        if (val) {
          let c = val.class == '0' ? '中国' : "国外"
          this.form = this.$tool.clone(val);
          // this.form.belong = this.form.belong.split("/");
          this.form.belong = [c, val.province, val.city, val.district];
          console.log(this.form.belong, "belong_options")
          // console.log(this.form, "fffffff");
          this.form.accountType = this.form.accountType === "经销商" ? 2 : 1;
          this.form.dealer = val.pid;
          // console.log(val,this.form, "vallllllllll")
          this.getCheckAgencyList(val.id);
        } else {
          this.form = {
            name: "",
            contact: "",
            account: "",
            accountType: 1,
            dealer: "",
            password: "",
            repassword: "",
            belong: [],
          };
        }
      },
      deep: true,
      immediate: true,
    },
    show: {
      handler(newV, oldV) {
        if (newV) {
          // this.getLocationList();
        }
      },
    },
  },
  mounted() {
    this.getCheckAgencyList();
    this.getRegionList(0)
    this.getRegionList(1)
  },
  methods: {
    // 获取下拉地区
    getRegionList(type) {
      let param = {
        // belongId: "",
        class: type
      }
      getLocation(param).then((res) => {
        if (res.code == 200) {
          res.data.map(item=>{
            item.pid = item.value.length > 2 ? item.value.slice(0, -2) : 0
          })
          if(type == 0){
            this.belong_options[0].children = this.generateOptions(res.data)
          } else {
            this.belong_options[1].children = this.generateOptions(res.data)
          }
        }
      });
    },
    // 递归处理接口数据
    generateOptions(params) {
      let result = [];
      for (const param of params) {
        if (param.pid == 0) {
          // 判断是否为顶层节点
          let parent = {
            label: param.label,
            value: param.label,
            key: param.value,
          };
          parent.children = this.getchilds(param.value, params); // 获取子节点
          result.push(parent);
        }
      }
      return result;
    },
    getchilds(value, array) {
      const childs = [];
      for (const arr of array) {
        // 循环获取子节点
        if (arr.pid === value) {
          childs.push({
            label: arr.label,
            value: arr.label,
            key: arr.value,
          });
        }
      }
      for (const child of childs) {
        // 获取子节点的子节点
        const childscopy = this.getchilds(child.key, array);
        if (childscopy.length > 0) {
          child.children = childscopy;
        }
      }
      return childs;
    },
    getCheckAgencyList(id) {
      let param = {
        // id: this.rowInfo.id
        agent_id: id, // 当前登录账号的id
      };
      checkAgencyList(param).then((res) => {
        if (res.code === 200) {
          this.options = res.data.agent_info.map((item) => {
            return {
              id: item.id,
              account: item.username ? item.username : "/",
            };
          });
        }
      });
    },
    handleClose(done) {
      this.$refs["form"].resetFields();
      this.$emit("toggleShow", null);
      // 关闭编辑表单后点击添加用户后有默认值
      // this.form.contact = ""; // 关闭重置表单
      // this.form.accountType = 1; // 关闭重置表单
      // this.form.dealer = ""; // 关闭重置表单
      // this.form.name = "";
      // this.form.account = "";
      // this.form.password = "";
      // this.form.repassword = "";
      // this.form.belong = [];
      this.form = {
        name: "",
        contact: "",
        account: "",
        accountType: 1,
        dealer: "",
        belong: [],
      };
    },
    testCHange(){
      console.log(this.form.belong, "12312312")
    },
    selectChange(e) {
    },
    selectChange2(e) {
      this.form.pid = e;
    },
    change(e) {
      this.$forceUpdate();
    },
    submitForm() {
      // console.log(this.rowInfo)
      if (!this.rowInfo) {
        let param = {
          password: this.form.password,
          username: this.form.name,
          agent_id: this.form.dealer, // 归属经销商
          type: this.form.accountType, // 用户or经销商
          account: this.form.account,
          phone: this.form.contact,
          province: this.form.belong[1],
          city: this.form.belong[2],
          district: this.form.belong[3],
        };
        // return
        this.$refs["form"].validate((valid) => {
          if (valid) {
            // alert('submit!');
            userAdd(param).then((res) => {
              // console.log(res)
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  // message: '添加用户成功！',
                  message: this.$t("dialog.userList.addUser_success"),
                  duration: 2000,
                });
                this.$emit("toggleShow", false);
                this.$emit("refresh");
                this.$refs["form"].resetFields();
              }
              // else {
              //   this.$message({
              //     message: res.msg,
              //     type: "error",
              //     duration: 2000,
              //   });
              // }
            });
          } else {
            return false;
          }
        });
      } else {
        // console.log(this.form,this.rowInfo)
        // return
        let param = {
          type: this.form.accountType,
          // phone: this.form.contact,
          agent_id: this.form.pid,
          id: this.form.id,
          username: this.form.name,
          province: this.form.belong[1],
          city: this.form.belong[2],
          district: this.form.belong[3],
          phone: this.form.contact,
        };
        this.$refs["form"].validate((valid) => {
          if (valid) {
            // alert('submit!');
            editInfo(param).then((res) => {
              // console.log(res)
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  // message: '编辑该用户成功！',
                  message: this.$t("dialog.userList.editUser_success"),
                  duration: 2000,
                });
                this.$emit("toggleShow", false);
                this.$emit("refresh");
                this.$refs["form"].resetFields();
              }
              // else {
              //   this.$message({
              //     type: "warning",
              //     message: res.msg,
              //     duration: 2000,
              //   });
              //   this.$emit("toggleShow", false);
              // }
            });
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      }
      this.getCheckAgencyList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;

  .btn {
    width: 180px;
    height: 32px;
  }

  .save {
    background-color: $main_color;
    border-color: $main_color;
  }

  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}

.form {
  justify-content: space-between;
}

::v-deep {
  .el-dialog__body {
    text-align: center;
  }
}
</style>