<template>
	<el-dialog
		class="custom-dialog updataProgress"
		:visible.sync="dialogVisible"
		:show-close="false"
		width="126px"
		:before-close="handleClose"
	>
		<div class="updataProgress-main">
			<el-progress
				type="circle"
				:stroke-width="12"
				:percentage="progress"
				text-color="#00FF0A"
				color="#00FF0A"
			></el-progress>
		</div>
	</el-dialog>
</template>

<script>
	
	export default {
		name: "UpdataProgress",
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			progress:{
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				dialogVisible: false,
			};
		},
		watch: {
			show: {
				handler(val) {
					this.dialogVisible = val;
				},
				immediate: true,
			},
			progress: {
				handler(val){
					if(val == 100){
						setTimeout(()=>{
							this.handleClose()
						},1000)
					}
				}
			}
		},
		mounted(){
			// this.upload()
		},
		methods: {
			handleClose(done) {
				this.$emit("toggleShow", false);
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	.updataProgress {
		&-main {
			height: 126px;
			&::v-deep .el-progress__text {
				color: $progress_color;
				font-size: 18px !important;
			}
		}
	}
</style>