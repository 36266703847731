<template>
  <div class="locationInfo" :class="[deviceInfo.type]" v-loading="isLoading">
    <div class="locationInfo_box">
      <div class="title">位置状态</div>
      <div
        class="flex locationInfo-item"
        v-for="item in detailList"
        :key="item.label"
      >
        <span class="locationInfo-item-label"> {{ $t(item.label) }}: </span>
        <span class="locationInfo-item-value">
          {{ item.value }}
        </span>
      </div>
    </div>

    <!-- <div class="locationInfo-btns">
      <el-button type="success" class="btn save" size="small" @click="refresh">
        {{ $t("public.refresh") }}
      </el-button>
    </div> -->
  </div>
</template>

<script>
import $tool from "@/utils/tool";
import { receiverInfo } from "@/api/index";
export default {
  data() {
    return {
      isLoading: false,
      timer: null,
      key: [
        "longitude",
        "latitude",
        "elevation",
        "diff",
        "differential_state",
        "satellite_number",
        "HDOP",
        "UTCdate",
      ],
      detail: {
        longitude: "",
        latitude: "",
        elevation: "",
        diff: "",
        differential_state: "",
        satellite_number: "",
        PDOP: "",
        HDOP: "",
        TDOP: "",
        HRMS: "",
        VRMS: "",
        UTCdate: "",
      },
    };
  },
  activated() {
    this.timer = setInterval(() => {
      this.getReceiverInfo();
    }, 1000);
  },
  deactivated() {
    if (this.timer) {
      clearInterval(this.timer); //清除定时器
    }
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
    detailList() {
      return this.key.map((f) => {
        return {
          label: `label.locationInfo.${f}`,
          value: this.detail[f],
        };
      });
    },
  },
  methods: {
    diffStatus(status) {
      let res = "";
      switch (status) {
        case 0:
          res = this.$t("label.locationInfo.label[1]");
          break;
        case 1:
          res = this.$t("label.locationInfo.label[1]");
          break;
        case 2:
          res = this.$t("label.locationInfo.label[2]");
          break;
        case 3:
          res = this.$t("label.locationInfo.label[3]");
          break;
        case 4:
          res = this.$t("label.locationInfo.label[4]");
          break;
        case 5:
          res = this.$t("label.locationInfo.label[5]");
          break;
        case 6:
          res = this.$t("label.locationInfo.label[6]");
          break;
        case 7:
          res = this.$t("label.locationInfo.label[7]");
          break;
        default:
          res = "-"
          break;
      }
      return res
    },
    // 获取位置信息集
    getReceiverInfo() {
      // this.isLoading = true;
      let param = {
        // sn:JSON.parse(localStorage.getItem('receiverInfo')).sn
        sn: sessionStorage.getItem("currentSn"),
        model: 2
      };
      receiverInfo(param).then((res) => {
        if (res.code === 200) {
          // this.isLoading = false;
          // 对返回字段的key处理
          this.detail = {
            latitude: res.data.lat + "°",
            longitude: res.data.lon + "°",
            elevation: res.data.height + "m",
            satellite_number: res.data.satellite_num,
            // differential_state: res.data.difference_status,
            differential_state: this.diffStatus(res.data.loc),
            UTCdate: res.data.utc_time ? res.data.utc_time : "-",
            HDOP: $tool.toFixedFn(res.data.hdop, 3, true),
            // diff: res.data.geoid, // 大地水准面差距
            diff: $tool.toFixedFn(res.data.geoid, 7, true), // 大地水准面差距
          };
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.locationInfo {
  height: 100%;
  padding: 20px;
  font-size: 14px;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #659b5d;
  }
  &_box {
    width: 25%;
    border: 1px solid #659b5d;
    border-radius: 5px;
    padding: 10px;
  }
  &-item {
    justify-content: space-between;
    font-size: 14px;
    &-value {
      line-height: 32px;
    }
    &-label {
      line-height: 32px;
    }
  }
  &-btns {
    justify-content: center;
    margin-top: 30px;
    .btn {
      width: 160px;
      height: 32px;
    }
    .save {
      background-color: $main_color;
      border-color: $main_color;
    }
    .cancel {
      border-color: $main_color;
      background-color: #fff;
      color: $main_color;
    }
  }
}
.MOBIE {
  .locationInfo-item {
    justify-content: space-between;
    & > span {
      width: 50%;
    }
    &-value {
      text-align: right;
      word-break: break-all;
    }
    &-label {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
}
::v-deep {
  .el-loading-spinner .path {
    stroke: #659b5d;
  }
}
</style>