<template>
  <div>
    <div class="satellite-radio">
      <el-checkbox-group v-model="checkList" text-color="#333333">
        <el-checkbox v-for="item in satellites" :key="item.value" :label="item.value"></el-checkbox>
      </el-checkbox-group>
    </div>
    <el-table :data="data" ref="tableData" stripe :height="tableHeight">
      <el-table-column v-for="column in columns" :key="column" :prop="column"
        :label="$t(`label.satellite.${dict[column]}`)" align="center" min-width="100px">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    satellites: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      tableHeight: 400,      
      columns: [
        "type",
        "id",
        "hight_angle",
        "position_angle",
        "signal1",
        "SNR1",
        "signal2",
        "SNR2",
        "signal3",
        "SNR3",
        "signal4",
        "SNR4",
        "signal5",
        "SNR5",
        "signal6",
        "SNR6",
        // "b1L1",
        // "b2L2",
        // "b3L3",
      ],
      dict: {
        "type": "satellite_type",
        "id": "satellite_code",
        "hight_angle": "elevating_angle",
        "position_angle": "azimuth",
        "signal1": "signal1",
        "SNR1": "SNR1",
        "signal2": "signal2",
        "SNR2": "SNR2",
        "signal3": "signal3",
        "SNR3": "SNR3",
        "signal4": "signal4",
        "SNR4": "SNR4",
        "signal5": "signal5",
        "SNR5": "SNR5",
        "signal6": "signal6",
        "SNR6": "SNR6",
        // "b1L1": "B1_L1",
        // "b2L2": "B2_L2",
        // "b3L3": "B3_L3",
      },
      checkList: this.satellites.map(f => f.value),
    }
  },
  watch: {
    '$store.getters.deviceInfo': {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    data() {
      return this.tableData.filter(f => {
        return this.checkList.includes(f.type);
      })
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.tableHeight =
      window.innerHeight - this.$refs.tableData.$el.offsetHeight + 150;
      console.log(this.tableHeight)
    })
  },
  methods: {
    // test() {
    //   let arr = ['a', 'b', 'c']
    //   this.tableData = []
    //   arr.forEach((item)=>{
    //     res.data.forEach(it=>{
    //       if(it.name == item){
    //         this.tableData.push(it)
    //       }
    //     })
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
.satellite-radio {
  margin: 15px 0;
}
</style>