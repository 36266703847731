/**
腾讯地图：	火星坐标系，	又名GCJ02坐标系
Google地图：火星坐标系，	又名GCJ02坐标系
百度地图：	BD坐标系		又名BD-09坐标系
GPS准确坐标：国际标准坐标系	又名WGS84坐标系
**/
//百度->腾讯
function Baidu_Tencent(lat, lon) {
    return GPS.bd_decrypt(lat, lon);
}
//百度->谷歌
function Baidu_Google(lat, lon) {
    return GPS.bd_decrypt(lat, lon);
}
//腾讯->百度
function Tencent_Baidu(lat, lon) {
    return GPS.bd_encrypt(lat, lon);
}
//腾讯->谷歌
function Tencent_Google(lat, lon) {
    return { 'lat': lat, 'lon': lon };
}
//谷歌->腾讯
function Google_Tencent(lat, lon) {
    return { 'lat': lat, 'lon': lon };
}
//谷歌->百度
function Google_Baidu(lat, lon) {
    return GPS.bd_encrypt(lat, lon);
}
//GPS->谷歌
function GPS_Google(lat, lon) {
    return GPS.gcj_encrypt(lat, lon);
}
//GPS->腾讯
function GPS_Tencent(lat, lon) {
    return GPS.gcj_encrypt(lat, lon);
}
//GPS->百度
function GPS_Baidu(lat, lon) {
    var point = GPS.gcj_encrypt(lat, lon);
    return GPS.bd_encrypt(point.lat, point.lon);
}
//谷歌->GPS
function Google_GPS(lat, lon) {
    return GPS.gcj_decrypt(lat, lon);
}
//腾讯->GPS
function Tencent_GPS(lat, lon) {
    return GPS.gcj_decrypt(lat, lon);
}
//百度->GPS
function Baidu_GPS(lat, lon) {
    var point = GPS.bd_decrypt(lat, lon);
    return GPS.gcj_decrypt(point.lat, point.lon);
}

var GPS = {
    PI: 3.14159265358979324,
    x_pi: 3.14159265358979324 * 3000.0 / 180.0,
    delta: function (lat, lon) {
        var a = 6378245.0;
        var ee = 0.00669342162296594323;
        var dLat = this.transformLat(lon - 105.0, lat - 35.0);
        var dLon = this.transformLon(lon - 105.0, lat - 35.0);
        var radLat = lat / 180.0 * this.PI;
        var magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        var sqrtMagic = Math.sqrt(magic);
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * this.PI);
        dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * this.PI);
        return { 'lat': dLat, 'lon': dLon };
    },
    gcj_encrypt: function (wgsLat, wgsLon) {
        if (this.outOfChina(wgsLat, wgsLon))
            return { 'lat': wgsLat, 'lon': wgsLon };

        var d = this.delta(wgsLat, wgsLon);
        return { 'lat': wgsLat + d.lat, 'lon': wgsLon + d.lon };
    },
    gcj_decrypt: function (gcjLat, gcjLon) {
        if (this.outOfChina(gcjLat, gcjLon))
            return { 'lat': gcjLat, 'lon': gcjLon };

        var d = this.delta(gcjLat, gcjLon);
        return { 'lat': gcjLat - d.lat, 'lon': gcjLon - d.lon };
    },
    bd_encrypt: function (gcjLat, gcjLon) {
        var x = gcjLon, y = gcjLat;
        var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi);
        var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi);
        var bdLon = z * Math.cos(theta) + 0.0065;
        var bdLat = z * Math.sin(theta) + 0.006;
        return { 'lat': bdLat, 'lon': bdLon };
    },
    bd_decrypt: function (bdLat, bdLon) {
        var x = bdLon - 0.0065, y = bdLat - 0.006;
        var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi);
        var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi);
        var gcjLon = z * Math.cos(theta);
        var gcjLat = z * Math.sin(theta);
        return { 'lat': gcjLat, 'lon': gcjLon };
    },
    outOfChina: function (lat, lon) {
        if (lon < 72.004 || lon > 137.8347)
            return true;
        if (lat < 0.8293 || lat > 55.8271)
            return true;
        return false;
    },
    transformLat: function (x, y) {
        var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin(y / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(y / 12.0 * this.PI) + 320 * Math.sin(y * this.PI / 30.0)) * 2.0 / 3.0;
        return ret;
    },
    transformLon: function (x, y) {
        var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin(x / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(x / 12.0 * this.PI) + 300.0 * Math.sin(x / 30.0 * this.PI)) * 2.0 / 3.0;
        return ret;
    }
};

module.exports = {
    Baidu_Tencent,
    Baidu_Google,
    Tencent_Baidu,
    Tencent_Google,
    Google_Tencent,
    Google_Baidu,
    Google_GPS,
    GPS_Google,
    GPS_Tencent,
    GPS_Baidu,
    Tencent_GPS,
    Baidu_GPS,
}