<template>
  <div class="container">
    <div class="search">
      <div class="flex">
        <div>{{ $t("label.positionMap.sn") }}：</div>
        <el-select
          v-model="snValue"
          filterable
          remote
          reserve-keyword
          :placeholder="$t('label.positionMap.snPlaceholder')"
          :remote-method="remoteMethod"
          :loading="loading"
          size="mini"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex mgl20">
        <div>{{ $t("label.positionMap.status") }}：</div>
        <el-select v-model="statusValue" size="mini">
          <el-option
            v-for="item in $t('label.positionMap.statusOption')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex mgl20">
        <el-button size="mini" type="primary" @click="search">{{
          $t("label.positionMap.search")
        }}</el-button>
        <el-button size="mini" type="warning" @click="reset">{{
          $t("label.positionMap.reset")
        }}</el-button>
      </div>
    </div>
    <!-- 基础地图组件 -->
    <Map ref="map" @load="handlerMapLoad"></Map>
  </div>
</template>
  
  <script>
import { getSN } from "@/api/index";
import Vue from "vue";
import mapboxgl from "mapbox-gl";
import Map from "./component/map.vue";
import "mapbox-gl/dist/mapbox-gl.css"; // 引入控件样式
import online from "@/assets/images/public/online.png"; // 在线
import offline from "@/assets/images/public/offline.png"; // 离线
import activated from "@/assets/images/public/online_active.png"; // 在线激活状态
import offline_active from "@/assets/images/public/offline_active.png"; // 在线激活状态
// import activated from "@/assets/images/public/online_active.png"; // 在线激活状态
import MapPopup from "./component/mapPopup.vue"; // 地图弹窗
import { Baidu_GPS } from "../../utils/coord"; // 百度转GPS经纬度
export default {
  name: "positionMap",
  components: {
    Map,
    // MapPopup
  },
  data() {
    return {
      timer: null,
      statusValue: "0", // 状态下拉值
      snValue: "", // sn输入值
      options: [], // sn
      loading: false,
      popup: null,
      points: [], // 坐标点
      wbs: null,
    };
  },
  watch: {
    points: {
      handler(newV) {
        if (newV && newV.length) {
          this.addMapPoints();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  activated() {
    this.remoteMethod();
  },
  deactivated() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  // beforeDestroy(){
  //   if (this.timer) {
  //     clearInterval(this.timer);
  //     this.timer = null;
  //   }
  // },
  methods: {
    handlerMapLoad(map) {
      this.map = map;
      this.loadImages();
      this.setDefaultLayer();
    },
    loadImages() {
      this.map.loadImage(online, (error, image) => {
        // 离线
        if (error) throw error;
        this.map.addImage("online", image);
      });
      this.map.loadImage(offline, (error, image) => {
        // 离线
        if (error) throw error;
        this.map.addImage("offline", image);
      });
      this.map.loadImage(activated, (error, image) => {
        // 选中
        if (error) throw error;
        this.map.addImage("activated", image);
      });
      this.map.loadImage(offline_active, (error, image) => {
        // 选中
        if (error) throw error;
        this.map.addImage("offline_active", image);
      });
    },
    // 设置默认图层
    async setDefaultLayer() {
      this.map.addSource("trackPoint", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      // 接收机聚合
      this.map.addSource("receiver", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 50,
      });
      // 接收机平铺点
      this.map.addLayer({
        id: "receiverPoint",
        type: "symbol",
        source: "receiver", // reference the data source
        filter: ["!=", "id", ""],
        layout: {
          "icon-allow-overlap": true, // 是否允许覆盖
          "icon-image": [
            "case",
            ["all", ["==", ["get", "status"], 1]],
            "online",
            ["all", ["==", ["get", "status"], 2]],
            "offline",
            "online",
          ], // reference the image
          "icon-size": 0.7,
          visibility: "visible",
          // "icon-anchor": "center",
        },
      });
      // 接收机聚合点
      this.map.addLayer({
        id: "receiverClusters",
        type: "circle",
        source: "receiver",
        filter: ["all", ["has", "point_count"]],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#719a63",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
          "circle-stroke-color": "#45733e",
          "circle-stroke-width": 2,
        },
      });
      // 聚合样式
      this.map.addLayer({
        id: "receiverClustersCount",
        type: "symbol",
        source: "receiver",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          // "text-font": ["Arial Unicode MS Bold"],
          "text-size": 18,
        },
      });
      // 点击高亮图标
      this.map.addLayer({
        id: "highLight",
        type: "symbol",
        filter: ["in", "id", ""],
        // type: "circle",
        metadata: {
          type: "ball",
        },
        source: "receiver", // reference the data source
        // paint: { // 设置透明度
        //   "icon-opacity": ["match", ["get", "status"], 1, 1, 2, 0.6, 0.6],
        // },
        layout: {
          "icon-allow-overlap": true,
          // "icon-image": "activated", // reference the image
          "icon-image": [
            "case",
            ["all", ["==", ["get", "status"], 1]],
            "activated",
            ["all", ["==", ["get", "status"], 2]],
            "offline_active",
            "activated",
          ], // reference the image
          "icon-size": 0.7,
          visibility: "visible",
          // "icon-anchor": "center",
        },
      });

      this.map.on("mousemove", this.handlerMapHover);
      this.map.on("click", this.handlerMapClick);
      // 聚合点点击放大
      this.map.on("click", "receiverClusters", (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ["receiverClusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map
          .getSource("receiver")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });
      this.getMapData();
    },
    // 获取数据
    getMapData(active) {
      if (!this.wbs) {
        // this.wbs = new WebSocket(
        //   "ws://nxa34kegc963.allynav.cn:9000/receiver/v1/gnss/device/all"
        // );
        // this.wbs = new WebSocket("ws://47.104.212.222:13016/v1/gnss/device/all");
        this.wbs = new WebSocket("wss://bdrmp.allynav.cn/api/v1/gnss/device/all");
        this.wbs.onopen = () => {
          this.wbs.send(
            JSON.stringify({
              sn: this.snValue ? [this.snValue] : [],
              online_status: this.statusValue ? Number(this.statusValue) : 0,
              model: 2,
              token: JSON.parse(localStorage.getItem("token")),
            })
          );
        };
      } else {
        this.wbs.send(
          JSON.stringify({
            sn: this.snValue ? [this.snValue] : [],
            online_status: this.statusValue ? Number(this.statusValue) : 0,
            model: 2,
            token: JSON.parse(localStorage.getItem("token")),
          })
        );
      }
      // this.wbs.onclose = (e) => {
      //   console.log(e, "websocket 断开");
      //   this.wbs = new WebSocket(
      //     "ws://114.215.129.119:9000/receiver/v1/gnss/device/all"
      //   );
      // };
      return new Promise((resolve) => {
        this.wbs.onmessage = (e) => {
          const res = JSON.parse(e.data);
          if (res.code !== 200) {
            // this.$message.warning(this.$t("请求错误"));
            this.$message.error(res.message);
            // this.$router.push('/login')
            // this.setTimer(true);
            resolve(false);
          } else {
            if (active) {
              if (res.data.device_info && res.data.device_info.length) {
                let propsData = {
                  data: {
                    model: res.data.device_info[0].model,
                    name: res.data.device_info[0].username,
                    sn: res.data.device_info[0].sn,
                    status: res.data.device_info[0].online_status,
                    time: res.data.device_info[0].last_online,
                  },
                  lang: this.$t("label.positionMap"),
                };
                this.toFly([
                  res.data.device_info[0].lon ? res.data.device_info[0].lon : 0,
                  res.data.device_info[0].lat ? res.data.device_info[0].lat : 0,
                ]);
                // 图标显示为激活状态
                // console.log(res, "pppp");
                this.createPopup(
                  [
                    res.data.device_info[0].lon
                      ? res.data.device_info[0].lon
                      : 0,
                    res.data.device_info[0].lat
                      ? res.data.device_info[0].lat
                      : 0,
                  ],
                  propsData
                );
                this.map.setFilter("highLight", ["in", "id", this.snValue]); // 点击显示包含此id的激活状态图标
                this.map.setFilter("receiverPoint", ["!=", "id", this.snValue]); // 点击后隐藏掉此id的未激活状态图标
              } else {
                this.$message.warning(this.$t("label.positionMap.noData"));
              }
              // 重置状态
              active = false;
            } else {
              if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
              }
              // 定时发送一次数据防止断联
              this.timer = setInterval(() => {
                // 此刻说明断联了
                if (this.wbs.bufferedAmount !== 0) {
                  // this.wbs = new WebSocket(
                  //   "ws://nxa34kegc963.allynav.cn:9000/receiver/v1/gnss/device/all"
                  // );
                  this.wbs = new WebSocket("ws://bdrmp.allynav.cn/api/v1/gnss/device/all");
                  this.wbs.onopen = () => {
                    this.wbs.send(
                      JSON.stringify({
                        sn: this.snValue ? [this.snValue] : [],
                        online_status: this.statusValue
                          ? Number(this.statusValue)
                          : 0,
                        model: 2,
                        token: JSON.parse(localStorage.getItem("token")),
                      })
                    );
                  };
                  // console.log(this.wbs);
                }
                this.wbs.send(
                  JSON.stringify({
                    sn: [],
                    online_status: 0,
                    model: 2,
                    token: JSON.parse(localStorage.getItem("token")),
                  })
                );
              }, 5000);
              this.points = res.data.device_info.map((item) => {
                return {
                  type: "Feature",
                  properties: {
                    id: item.sn || "-",
                    status: item.online_status || "-",
                    name: item.username || "-",
                    model: item.model || "-",
                    sn: item.sn || "-",
                    time: item.last_online || "-",
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [
                      item.lon ? item.lon : 0,
                      item.lat ? item.lat : 0,
                    ],
                  },
                };
              });
              resolve(res);
            }
          }
        };
      });
      // let param = {
      //   sn: [],
      //   status: 0,
      //   token: JSON.parse(localStorage.getItem('token'))
      // };
      // mapSearch(param).then((res) => {
      //   if (res.code == 200) {
      //     this.points = res.data.map((item) => {
      //       return {
      //         type: "Feature",
      //         properties: {
      //           id: item.sn,
      //           status: item.online_status,
      //           name: item.belong_name,
      //           model: item.model,
      //           sn: item.sn,
      //           time: item.last_online,
      //         },
      //         geometry: {
      //           type: "Point",
      //           coordinates: [item.lon, item.lat],
      //         },
      //       };
      //     });
      //   }
      // });
    },
    // 添加地图点
    addMapPoints() {
      // 没用到
      this.map.getSource("trackPoint").setData({
        type: "FeatureCollection",
        features: this.points,
      });
      this.map.getSource("receiver").setData({
        type: "FeatureCollection",
        features: this.points,
      });
    },
    // 处理地图鼠标悬浮事件
    handlerMapHover(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["receiverClustersCount", "receiverClusters", "receiverPoint"],
      });
      if (features.length) {
        let hasLine = features.find(
          (f) => f.layer.id == "trackLine" || f.layer.id == "realTrackLine"
        );
        this.map.getCanvas().style.cursor = "pointer";
      } else {
        // this.highLigth(true);
        this.map.getCanvas().style.cursor = "";
      }
    },
    // 处理地图点击事件
    handlerMapClick(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["receiverPoint", "receiverClusters"],
      });
      if (features.length) {
        if (this.popup) {
          this.popup.remove();
          this.popup = null;
        }
        if (
          features[0].layer.id == "receiverPoint" &&
          features[0].properties.id
        ) {
          // 图标显示为激活状态
          this.map.setFilter("highLight", [
            "in",
            "id",
            features[0].properties.id,
          ]); // 点击显示包含此id的激活状态图标
          this.map.setFilter("receiverPoint", [
            "!=",
            "id",
            features[0].properties.id,
          ]); // 点击后隐藏掉此id的未激活状态图标
          let propsData = {
            // 弹窗需要的数据
            data: features[0].properties,
            lang: this.$t("label.positionMap"),
          };
          this.createPopup(features[0].geometry.coordinates, propsData);
          // const p = Vue.extend(MapPopup);
          // let vm = new p({
          //   propsData: {
          //     data: features[0].properties,
          //     lang: this.$t("label.positionMap"),
          //   }, //传参
          // });
          // vm.$mount(); //挂载
          // this.popup = new mapboxgl.Popup()
          //   .setLngLat(features[0].geometry.coordinates)
          //   .setMaxWidth("400px") //设置弹窗最大宽度
          //   .setDOMContent(vm.$el) //插入节点
          //   .setOffset({ bottom: [0, -10] })
          //   .addTo(this.map);
        }
      } else {
        // this.map.setFilter("highLight", ["in", "id", ""]); // 点击显示包含此id的图标
        // this.map.setFilter("receiverPoint", ["!=", "id", ""]); // 点击后只显示不包含该id的图标
        this.clearPopup(); // 清除弹框
      }
    },
    createPopup(point = [], propsData = {}) {
      // if(this.popup){
      //   this.popup.remove();
      // }
      this.clearPopup();
      // console.log(propsData, "ppp");
      const p = Vue.extend(MapPopup);
      let vm = new p({
        propsData: propsData,
      });
      vm.$mount(); //挂载
      this.popup = new mapboxgl.Popup()
        .setLngLat(point)
        .setMaxWidth("200") //设置弹窗最大宽度
        .setDOMContent(vm.$el) //插入节点
        .setOffset({ bottom: [0, -10] })
        .addTo(this.map);
      this.popup.on("close", () => {
        this.map.setFilter("highLight", ["in", "id", ""]); // 点击显示包含此id的图标
        this.map.setFilter("receiverPoint", ["!=", "id", ""]); // 点击后只显示不包含该id的图标
      });
    },
    clearPopup() {
      if (this.popup) {
        this.popup.remove();
        this.popup = null;
      }
    },
    // 跳转到定位点
    toFly(point) {
      // console.log(point, "pppp");
      this.map.flyTo({
        center: point,
        zoom: 17,
        duration: 500,
        essential: true,
      });
    },
    /************************************/
    // 选中后清除数据
    // clearOptions(){
    //   this.options = []
    // },
    // 远程搜索出来的sn下拉数据
    remoteMethod(e) {
      let param = {
        sn: e,
        model: 2,
      };
      getSN(param).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          if (res.data) {
            this.options = res.data.sn.map((item) => {
              return {
                label: item,
                value: item,
              };
            });
          } else {
            this.options = [];
          }
        }
      });
    },
    // 搜索
    search() {
      if (this.snValue) {
        this.getMapData(true);
        // let param = {
        //   sn: this.snValue,
        //   status: this.statusValue,
        // };
        // mapSearch(param).then((res) => {

        // });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("label.positionMap.choose"),
        });
      }
    },
    // 重置
    reset() {
      (this.snValue = ""), (this.statusValue = "0");
      this.remoteMethod();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.container {
  height: calc(100% - 40px);
  width: 100%;
  .search {
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
.mgl20 {
  margin-left: 20px;
}
::v-deep {
  a.mapboxgl-ctrl-logo {
    display: none;
  }
}
::v-deep {
  .mapboxgl-popup-close-button {
    font-size: 24px;
    right: 4px;
    top: 4px;
  }
}
</style>