<template>
	<div class="locationInfo" :class="[deviceInfo.type]">
		<div
			class="flex locationInfo-item"
			v-for="item in detailList"
			:key="item.label"
		>
			<span class="locationInfo-item-label"> {{ $t(item.label) }}: </span>
			<span v-if="item.value == 'net_version'">{{ !detail[item.value] || detail[item.value] == 65535 ? "N/A" : detail[item.value] }}</span>
			<span v-else class="locationInfo-item-value">
				{{ detail[item.value] }}
			</span>
		</div>
		<div class="locationInfo-btns">
			<el-button
				v-for="btn in btns"
				:key="btn.type"
				type="success"
				class="btn cancel"
				size="small" 
				@click="openMessageDialog(btn.type)"
			>
				{{ $t(btn.label) }}
			</el-button>
		</div>
		<ConfirmDialog
			:title="message.title"
			:message="message.content"
			:show="showMessageDialog"
			@toggleShow="closeDia"
			@submit="closeDia"
			@option="toReceiverDiagnosis"
		></ConfirmDialog>
	</div>
</template>

<script>
	import ConfirmDialog from "../../components/public/confirmDialog";
	import {receiverInfo, receiverDiagnosis} from "@/api/index"
	export default {
		components: { ConfirmDialog },
		data() {
			return {
				detailList: [
					{
						label: "label.info.device_type",
						value: "model",
					},
					{
						label: "label.info.SN",
						value: "sn",
					},
					{
						label: "label.info.PN",
						value: "pn",
					},
					{
						// label: "label.info.mainboard_versions",
						label: "label.info.baseplate_versions",
						value: "mainboard_version",
					},
					{
						label: "label.info.network_versions",
						value: "net_version",
					},
					{
						// label: "label.info.baseplate_versions",
						label: "label.info.mainboard_versions",
						value: "baseboard_version",
					},
				],
				detail: {
					model: "",
					sn: "",
					pn: "",
					base_fireware_version: "",
					net_fireware_version: "",
					inter_fireware_version: "",
				},
				showMessageDialog: false,
				message: {
					title: this.$t("public.tip"),
					content: "",
				},
				btns: [
					{
						label: "label.info.reset",
						type: "reset",
					},
					{
						label: "label.info.init",
						type: "init",
					},
					{
						label: "label.info.restart",
						type: "restart",
					},
				],
				btnType:'',
				message_success: "",
				message_err: "",
			};
		},
		mounted(){
		},
		activated(){
			console.log(this.$route, "rrrrr")
			this.getReceiverInfo()
		},
		computed: {
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
		},
		methods: {
			// 获取接收机信息集
			getReceiverInfo(){
				let param = {
					sn: sessionStorage.getItem('currentSn'),
					model: 2
				}
				receiverInfo(param).then(res=>{
					// console.log('111',res)
					if(res.code === 200){
						this.detail = res.data
						this.detail.model = this.detail.model == 1 ? "R12" : "R51"
						sessionStorage.setItem("currentPn", res.data.pn)
						// console.log(this.detail, "ddd")
					}
				})
			},
			openMessageDialog(type) {
				this.btnType = type
				switch (type) {
					case "reset":
						this.message.content = this.$t("message.info.reset");
						break;
					case "init":
						this.message.content = this.$t("message.info.init");
						break;
					case "restart":
						this.message.content = this.$t("message.info.restart");
						break;
					default:
						break;
				}
				if (this.message.content) {
					this.showMessageDialog = true;
				}
			},
			closeDia() {
				this.showMessageDialog = false;
			},
			// 接收机诊断
			toReceiverDiagnosis() {
				let param = {
					// model: sessionStorage.getItem('currentModel'), // 设备类型
					sn: sessionStorage.getItem('currentSn'), //设备sn号
					num: 1,
					model: 2
				}
				let message = ''
				if(this.btnType === 'reset'){
					this.message_success = this.$t('label.info.reset_success')
					this.message_err = this.$t('label.info.reset_err')
					param.num = 1
				}else if(this.btnType === 'init'){
					this.message_success = this.$t('label.info.init_success')
					this.message_err = this.$t('label.info.init_err')
					param.num = 4
				}else if(this.btnType === 'restart'){
					this.message_success = this.$t('label.info.restart_success')
					this.message_err = this.$t('label.info.restart_err')
					param.num = 2
				}
				console.log(param)
				receiverDiagnosis(param).then(res => {
					if(res.code == 200){
						this.$message({
							type:'success',
							message:this.message_success,
							duration: 2000
						})
					}else {
						this.$message({
							type:'warning',
							message:res.message,
							duration: 2000
						})
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.locationInfo {
		padding: 10px;
		&-item {
			font-size: 14px;
			line-height: 32px;
			&-value {
				line-height: 32px;
			}
		}
		&-btns {
			justify-content: center;
			margin-top: 30px;
			.btn {
				min-width: 110px;
				height: 32px;
			}
			.save {
				background-color: $main_color;
				border-color: $main_color;
			}
			.cancel {
				border-color: $main_color;
				background-color: #fff;
				color: $main_color;
			}
		}
	}
	.MOBIE {
		.locationInfo-item {
			justify-content: space-between;
			& > span {
				width: 50%;
			}
			&-value {
				text-align: right;
				word-break: break-all;
			}
			&-label {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 600;
				color: #333333;
			}
		}
	}
</style>