<template>
  <div class="container">
    <div class="title">
      {{ $t("routes.antennaSet") }}
    </div>
    <div class="setting flex mgt20">
      <div class="label">{{ $t("label.antennaSet.antennaMeasure") }}：</div>
      <el-select v-model="value" placeholder="请选择" class="item" size="small">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="setting flex mgt20">
      <div class="label">{{ $t("label.antennaSet.antennaHight") }}：</div>
      <div class="item">{{ form.antennaHight }}m</div>
    </div>
    <div class="setting flex mgt20">
      <el-form :model="form" ref="form" :rules="rules" class="setting flex">
        <div class="label">{{ $t("label.antennaSet.measureHight") }}：</div>
        <div class="item">
          <el-form-item prop="measureHight">
            <el-input size="small" v-model="form.measureHight" maxlength="5">
              <template slot="append">m</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="setting flex mgt20">
      <div class="label">{{ $t("label.antennaSet.centerHight") }}：</div>
      <div class="item">{{ centerHight }}m</div>
    </div>
    <div class="mgt20">
      <el-button size="small" type="primary" @click="save">{{
        $t("label.antennaSet.save")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { antennaHigh, receiverInfo } from "@/api/index.js";
import tool from "../../utils/tool"
export default {
  name: "antennaSet",
  data() {
    let validValueHeight = (rule, value, callback) => {
      if (value < 0 || value > 4.999) {
        // 校验高度不为0
        callback(new Error("数值范围为0.000~4.999"));
      } 
      // else if (value.split(".")[1].length > 3) {
      //   callback(new Error("请最大精确到小数点后3位"));
      // } 
      else {
        callback();
      }
    };
    return {
      options: [
        // {
        //   value: "0",
        //   label: "未定义",
        // },
        // {
        //   value: "1",
        //   label: "到测量线的直高",
        // },
        {
          value: "2",
          label: "到仪器底部的直高",
        },
        // {
        //   value: "3",
        //   label: "到测量线斜高",
        // },
        // {
        //   value: "4",
        //   label: "到测高片斜高",
        // },
        // {
        //   value: "5",
        //   label: "到相位中心高",
        // },
      ],
      rules: {
        measureHight: [{ validator: validValueHeight, trigger: "blur" }],
      },
      value: "1",
      form: {
        antennaHight: 0.068,
        measureHight: 0.0,
      },
      // form: {

      // }
    };
  },
  computed: {
    centerHight: {
      get() {
        return tool.toFixedFn(this.form.antennaHight + Number(this.form.measureHight), 3)
      },
      set(value) {},
    },
  },
  mounted() {},
  activated() {
    this.getInitData();
  },

  methods: {
    getInitData() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 2
      };
      receiverInfo(param).then((res) => {
        if (res.code == 200) {
          // this.value = res.data.antenna_measure_type + "";
          this.value = "2"; // 0/1都默认为2
          this.form.measureHight = res.data.antenna_measure_height;
        }
      });
    },
    save() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        antenna_measure_type: this.value,
        antenna_measure_height: this.form.measureHight,
        antenna_phase_height: this.centerHight,
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          antennaHigh(param).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: this.$t("label.antennaSet.success"),
              });
            } else {
              this.$message({
                type: "error",
                message: "网络错误请重试！",
              });
            }
          });
        } else {
          return false;
        }
      });
      // console.log(param)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  font-size: 14px;
  .title{
    font-weight: 600;
    font-size: 16px;
  }
}
.label {
  min-width: 150px;
}
.mgt20 {
  margin-top: 20px;
}
.item {
  width: 250px;
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
.el-form-item{
  margin: 0;
}
</style>