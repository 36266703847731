<template>
  <div class="postSet">
    <div class="title">{{ $t("label.postSet.page_title") }}</div>
    <div class="postSet-table">
      <el-table
        :data="tableData"
        ref="tableData"
        style="width: 100%"
        :height="tableHeight"
        stripe
        empty-text=" "
        v-loading="isLoading"
      >
        <template v-for="column in columns">
          <template v-if="column == 'index'">
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              min-width="60"
              align="center"
            >
            </el-table-column>
          </template>
          <template v-else-if="column == 'status'">
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              min-width="60"
              align="center"
            >
              <!-- ? 'green' : 'orange' -->
              <template slot-scope="{ row }">
                <div
                  class="normal"
                  :style="{
                    background:
                      row.status == 2
                        ? 'green'
                        : row.status == 1
                        ? 'orange'
                        : '#eeeeee',
                  }"
                ></div>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="column == 'operate'">
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              align="center"
              min-width="150"
            >
              <template slot-scope="{ row }">
                <el-button
                  size="mini"
                  v-if="!(row.index == 1 || row.index == 2)"
                  :disabled="row.status != 1 && row.status != 2"
                  @click="startOrStop(row)"
                  :type="
                    row.status == 2
                      ? 'danger'
                      : row.status == 1
                      ? 'primary'
                      : ''
                  "
                  >{{
                    row.status == 2
                      ? $t("label.postSet.stop")
                      : row.status == 1
                      ? $t("label.postSet.start")
                      : $t("label.postSet.start")
                  }}</el-button
                >
                <el-button
                  size="mini"
                  v-if="!(row.index == 1 || row.index == 2)"
                  :disabled="row.status != 1 && row.status != 2"
                  :type="row.status != 1 && row.status != 2 ? '' : 'primary'"
                  @click="openEditPost(row)"
                  >{{ $t("label.postSet.setting") }}</el-button
                >
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column
              :key="column"
              :prop="column"
              :label="$t(`label.postSet.${column}`)"
              align="center"
              min-width="100"
            >
              <!-- <template slot-scope="{ row }">
                <template v-if="column == 'input'">
                  <span v-if="row.input">
                    {{ row[column] }}
                  </span>
                  <span v-else> —— </span>
                </template>
                <template v-else-if="column == 'output'">
                  <span v-if="row.output">
                    {{ row[column] }}
                  </span>
                  <span v-else> —— </span>
                </template>
                <template v-else>
                  {{ row[column] ? row[column] : "——" }}
                </template>
              </template> -->
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>
    <edit-post
      :show="showPost"
      :rowInfo="rowInfo"
      @toggleShow="closeDialog"
      @refush="getIOList"
      @start="startOrStop"
    ></edit-post>
  </div>
</template>

<script>
import { IOList, startOrStop } from "@/api/index";
import EditPost from "./component/editPost.vue";
export default {
  components: {
    EditPost,
  },
  data() {
    return {
      tableHeight: 500,
      isLoading: false,
      start: true,
      columns: [
        "index",
        "status",
        "type",
        "ip",
        "post",
        // "input",
        // "output",
        "operate",
      ],
      tableData: [],
      showPost: false,
      rowInfo: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },

  watch: {
    "$store.getters.deviceInfo": {
      handler(val) {
        // console.log(val);
        this.$nextTick(() => {
          this.$refs.tableData.doLayout();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.getIOList();
  },
  mounted() {
    this.tableHeight =
      window.innerHeight - this.$refs.tableData.$el.offsetHeight + 190;
  },
  methods: {
    startOrStop(row, test) {
      if (!row.ip) {
        this.$message.warning(this.$t("label.postSet.config"));
        return;
      }
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        ip_syn: row.ip_syn,
        ip: row.ip,
      };
      let msg = "";
      if (row.status == 1) {
        // 开始配置
        this.$message(this.$t("label.postSet.starting"));
        param.ip_status = 2;
        msg = this.$t("label.postSet.start_success");
        // console.log("开始")
      } else {
        // 停止配置
        this.$message(this.$t("label.postSet.stoping"));
        param.ip_status = 1;
        msg = this.$t("label.postSet.stop_success");
        // console.log("停止")
      }
      console.log(param, "param");
      startOrStop(param).then((res) => {
        if (res.code == 200) {
          this.$message.success(msg);
          if (row.status == 2) {
            row.status = 1;
          } else {
            row.status = 2;
          }
          // row.status = !row.status;
        } else {
          this.$message.error(res.message);
        }
      });
      // this.start = !this.start
    },
    switchName(type) {
      let name = "";
      switch (type) {
        case 1:
          name = "Ntrip client";
          break;
        case 2:
          name = "Ntrip server";
          break;
        case 3:
          name = "TCP";
          break;
        default:
          name = "——";
          break;
      }
      return name;
    },
    getIOList() {
      this.isLoading = true;
      let param = {
        sn: JSON.parse(sessionStorage.getItem("currentSn")),
      };
      return new Promise((resolve) => {
        IOList(param).then((res) => {
          if (res.code == 200) {
            // console.log(res,"-=-=-=-=-")
            this.isLoading = false;
            this.tableData = res.data.rows.map((item, index) => {
              return {
                index: item.ip_syn,
                status: item.state,
                ip: item.ip,
                type: this.switchName(item.protocol),
                name: item.protocol,
                post: item.port,
                input: item.input ? item.input : "-",
                output: item.output,
                ip_syn: item.ip_syn,
                nmea_gga: item.nmea_gga,
                nmea_gsa: item.nmea_gsa,
                nmea_gst: item.nmea_gst,
                nmea_gsv: item.nmea_gsv,
                nmea_rmc: item.nmea_rmc,
                nmea_gll: item.nmea_gll,
                nmea_zda: item.nmea_zda,
                nmea_vtg: item.nmea_vtg,
                smooth_switch: item.smooth_switch,
                smooth_interval: item.smooth_interval,
                rm_float: item.rm_float,
                prefix_sn: item.prefix_sn,
                account: item.account,
                password: item.password,
                access_point: item.access_point
              };
            });
            resolve(true);
          } else {
            this.isLoading = false;
          }
        });
      });
    },
    // 格式化ip
    formatIP(ip) {
      ip = ip
        .split(".")
        .map((item) => {
          if (item.startsWith("00")) {
            return item.slice(2);
          }
          if (item.startsWith("0")) {
            return item.slice(1);
          } else {
            return item;
          }
        })
        .join(".");
      return ip;
    },
    openEditPost(val) {
      // console.log(val);
      this.showPost = true;
      this.rowInfo = val;
    },
    closeDialog() {
      this.showPost = false;
      this.rowInfo = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.postSet {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  .title {
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
  }
  &-table {
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;
  }
  &::v-deep th.el-table__cell {
    background-color: $table_header;
    color: #fff;
  }
  &::v-deep td.el-table__cell {
    background: transparent !important;
  }
  &::v-deep .el-table__row--striped {
    background-color: #d2e9ce;
  }
  &::v-deep .el-table .cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
::v-deep {
  .el-loading-spinner .path {
    stroke: #659b5d;
  }
}
.normal,
.nodata,
.warning {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  // border: 1px solid #000;
  // background-color: #128122;
  background-color: #ff8d1a;
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
.el-button--danger {
  background-color: #f56c6c;
}
</style>