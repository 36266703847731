<template>
	<div class="updata">
		<updata-cell
			v-for="(item,index) in list"
			:key="item.label"
			:title="item.label"
			:files="item.fileList"
			:index="index"
			@updataFirmware="updataFirmware(index)"
			@getIndex="getIndex"
			@getPath="getPath"
		></updata-cell>
		<ConfirmDialog
			:title="message.title"
			:message="message.content"
			:show="showMessage"
			@toggleShow="closeDialog"
			@submit="closeDialog"
		></ConfirmDialog>
		<updata-progress
			:show="showProgress"
			:progress="mockProgress"
			@toggleShow="closeDialog"
		></updata-progress>
	</div>
</template>

<script>
	import updataCell from "./component/updataCell.vue";
	import ConfirmDialog from "../../components/public/confirmDialog";
	import UpdataProgress from "./component/updataProgress.vue";
	import { receiverUpdate } from "@/api/index"
	export default {
		components: { updataCell, ConfirmDialog, UpdataProgress },
		data() {
			return {
				list: [
					{
						label: this.$t("label.updata.mainboard_firmware"),
						fileList: [],
					},
					{
						label: this.$t("label.updata.baseplate_firmware"),
						fileList: [],
					},
					{
						label: this.$t("label.updata.station_firmwar"),
						fileList: [],
					},
					{
						label: this.$t("label.updata.network_firmwar"),
						fileList: [],
					},
					{
						label: this.$t("label.updata.lmu_firmwar"),
						fileList: [],
					},
				],
				showConfimePwd: false,
				showMessage: false,
				showProgress: false,
				message: {
					title: this.$t("public.tip"),
					content: this.$t("message.updata.noEquip"),
				},
				pathArr:[],
				uploadIndex: 0,
				mockProgress: 0
			};
		},
		deactivated(){
			this.pathArr = [] // 切换时清除缓存
		},
		mounted(){
			// let timer = null
			// this.showProgress = true
			// timer = setInterval(()=>{
			// 	if(this.mockProgress < 100){
			// 		this.mockProgress+=10
			// 	} else {
			// 		this.mockProgress = 100
			// 		this.closeDialog()
			// 		this.$message({
			// 			type: 'success',
			// 			message: "升级成功"
			// 		})
			// 		clearInterval(timer)
			// 		timer = null
			// 		return
			// 	}
			// },1000)
		},
		methods: {
			updataFirmware(index) {
				// console.log(index,this.pathArr)
				this.mockProgress = 0
				if(this.pathArr[index]){
					if(this.pathArr[index]){
					this.showProgress = true; // 显示进度条的
					let param = {
						// deviceid: JSON.parse(localStorage.getItem('receiverInfo')).sn, //设备sn号
						sn: sessionStorage.getItem('currentSn'), //设备sn号
						pn: sessionStorage.getItem("currentPn"), 
						tag: index+1, 
						file_name: this.pathArr[index],
						model: 2 
					}
					receiverUpdate(param).then(res=>{
						console.log(res)
						if(res.code === 200){
							this.$message({
								type:'success',
								// message:'固件升级成功！',
								message:this.$t("message.updata.update_success_info"),
								duration: 2000
							})
							this.mockProgress = 100
						} else {
							this.$message({
								type:'error',
								message:res.msg,
								duration: 2000
							})
							setTimeout(()=>{
								this.closeDialog()
							},2000)
						}
					})
					}else {
						this.$message({
							type:'warning',
							// message:"请先选择文件进行上传！",
							message:this.$t("message.updata.choose_file_warning"),
							duration: 2000
						})
					}
				}else{
					this.$message({
							type:'warning',
							// message:"请先选择文件进行上传！",
							message:this.$t("message.updata.choose_file_warning"),
							duration: 2000
						})
				}
				
			},
			openMessageDialog() {
				this.showMessage = true;
			},
			closeDialog() {
				this.showConfimePwd = false;
				this.showMessage = false;
				this.showProgress = false;
			},
			getPath(e){
				this.pathArr[this.uploadIndex] = e
				console.log(this.pathArr)
			},
			// 判断点击的是哪一个上传按钮 进行固件升级时判断需要
			getIndex(e){
				this.uploadIndex = e 
				// console.log(this.uploadIndex)
			}
		},
	};
</script>

<style lang="scss" scoped>
	.updata {
		padding: 0 20px;
	}
</style>