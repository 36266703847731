/** Created by postbird on 2016/4/1.  ...*/
/**
 *      @postbird
 *      1、本插件采用js编写，可直接将函数复制到个人js文件，减少get请求数
 *      2、author：powered by postbird
 *      3、email： ptbird@yeah.net
 *      4、site：http://www.ptbird.cn
 * */
/**
 文件说明：将gps的坐标转换成度形式的坐标方便计算 gpsToDu.js
 功能说明：
        将str1 和 str2 形式的坐标转换成 str3形式  【 度分秒格式和度分格式 转换成 度格式 】
             let str1="W 39°55′44″";//d-m-s  或者  str1="39°55′44″ W";
             let str2="N 39°55.7333′";//d-m   或者  str2="39°55.7333′ E";
             to:
             let str3="39.9288888885"; // d
 */
/**
 *  函数使用说明：
 *      1、直接调用函数  gpsToDu(gpsStr);
 *  返回说明： 返回结果数组
 *      1 gpsDu[0] 表示方向 ，小写字母 s w e n
 *      2 gpsDu[1] 表示计算的结果 ， 数字-19.8222222
 * */
/**
 *  优点： 自动处理空格与其他无关字符，只处理表示方向、小数点、数字三种字符
 *  缺点： 小数点必须为半角的小数点 .  不能是全角小数点
 * */
import tool from "./tool"
function convertDDMtoDD(degree, minute) {
    return (degree + minute / 60).toFixed(8);
}

function convertDMStoDD(degree, minute, second) {
    // console.log(degree, minute / 60, second / 3600, "aaaaaaa")
    return (degree + minute / 60 + second / 3600).toFixed(8);
}
export function gpsToDu(gpsStr) {
    let gpsDu = new Array();
    if(gpsStr.indexOf("′") !== -1 && gpsStr.indexOf("″") !== -1){
        // 度分秒
        let data = gpsStr.replace("°", ",").replace("′", ",").replace("″", ",").split(",")
        // console.log(data, "data")
        gpsDu[1] = convertDMStoDD(Number(data[0]), Number(data[1]), Number(data[2]))
    } else {
        let data = gpsStr.replace("°", ",").replace("′", ",").split(",")
        // console.log(data, "data")
        
        gpsDu[1] = convertDDMtoDD(Number(data[0]), Number(data[1]))
    }
    // gpsStr = gpsStr.toLowerCase();
    // gpsStr = gpsStr.replace(/\s+/g, "");
    // let tempStrArray = new Array();
    // let flag = 1;
    // let lastFlag = 0;
    // let strLength = gpsStr.length;
    // let gpsDir;
    // let tempcount = 0;
    // let tempString = "";
    // let tempPointFlag = 0;
    // let unit = ""
    // if(gpsStr.indexOf("-") != -1) {
    //     unit = "-"
    // }
    // if (gpsStr[0] == 'w' || gpsStr[0] == 's') {
    //     flag = -1;
    //     lastFlag = 0;
    //     gpsDir = gpsStr[0];
    // } else if (gpsStr[strLength - 1] == 'w' || gpsStr[strLength - 1] == 's') {
    //     flag = -1;
    //     lastFlag = 1;
    //     gpsDir = gpsStr[strLength - 1];
    // }
    // for (let i = 0; i <= strLength; i++) {
    //     if (gpsStr[i] >= '0' && gpsStr[i] <= '9') {
    //         tempString += gpsStr[i];
    //         continue;
    //     } else if (gpsStr[i] == '.') {
    //         tempStrArray[tempcount] = tempString;
    //         tempString = "";
    //         tempcount++;
    //         tempStrArray[tempcount] = '.';
    //         tempPointFlag = 1;
    //         tempcount++;
    //     } else if (tempString.length > 0) {
    //         tempStrArray[tempcount] = tempString;
    //         tempString = "";
    //         tempcount++;
    //     }
    // }
    // if (tempPointFlag == 0) {
    //     let num1 = parseFloat(tempStrArray[0], 10);
    //     let num2 = parseFloat(tempStrArray[1], 10);
    //     let num3 = parseFloat(tempStrArray[2], 10);
    //     gpsDu[1] = num1 + num2 / 60 + num3 / (60 * 60);
    //     gpsDu[1] = unit + gpsDu[1] * flag;
    //     gpsDu[0] = gpsDir;
    // } else if (tempPointFlag == 1) {
    //     // let num1 = parseFloat(tempStrArray[0], 10);
    //     // let num2 = parseFloat(tempStrArray[1] + '.' + tempStrArray[3], 10);
    //     // gpsDu[1] = tool.toFixedFn(num1 + num2 / 60 ,9)
    //     // gpsDu[1] = Number(unit + gpsDu[1] * flag).toFixed(8);
    //     // gpsDu[0] = gpsDir;
    //     // console.log(gpsDu, "gpsDu")
    //     if (tempStrArray.length == 5) {
    //         let num1 = parseFloat(tempStrArray[0], 10);
    //         let num2 = parseFloat(Number(tempStrArray[1]) / 60 + Number(tempStrArray[2] + '.' + tempStrArray[4]) / 3600);
    //         gpsDu[1] = (num1 + num2).toFixed(8)
    //         gpsDu[0] = gpsDir;
    //     } else if (tempStrArray.length == 4) {
    //         let num1 = parseFloat(tempStrArray[0], 10);
    //         let num2 = parseFloat(Number(tempStrArray[1] + '.' + tempStrArray[3]) / 30);
    //         gpsDu[1] = (num1 + num2).toFixed(8)
    //         gpsDu[0] = gpsDir;
    //     }
    // }
    // console.log(gpsDu, "gpsDu")
    return gpsDu;
}

export function duToGpsDMS(duStr) {
    // // console.log(duStr, "duStr")
    // duStr = duStr.toLowerCase();
    // duStr = duStr.replace(/\s+/g, "");
    // let strLength = duStr.length;
    // let tempString = "";
    // let tempStrArray = new Array();
    // let tempCount = 0;
    // let tempPointFlag = 0;
    // let gpsDMS;
    // let unit = ""
    // if(duStr.indexOf("-") != -1) {
    //     unit = "-"
    // }
    // for (let i = 0; i <= strLength; i++) {
    //     // console.log(duStr[i], "dddddddddddddddd")
    //     if (duStr[i] >= '0' && duStr[i] <= '9') {
    //         tempString += duStr[i];
    //         continue;
    //     } else if (duStr[i] == '.') {
    //         tempStrArray[tempCount] = tempString;
    //         tempString = "";
    //         tempCount++;
    //         tempStrArray[tempCount] = '.';
    //         tempPointFlag = 1;
    //         tempCount++;
    //     } else if (tempString.length > 0) {
    //         tempStrArray[tempCount] = tempString;
    //         tempString = "";
    //         tempCount++;
    //     }
    // }
    // if (tempPointFlag == 1) {
    //     let num1 = tempStrArray[0];
    //     let num2 = parseFloat('0' + tempStrArray[1] + tempStrArray[2], 10) * 60;
    //     let num3 = parseFloat((num2 - parseInt(num2, 10)) * 60, 10).toFixed(5);
    //     // let num3 = tool.toFixedFn(parseFloat((num2 - parseInt(num2, 10)) * 60, 10) ,5)
    //     num2 = parseInt(num2, 10);

    //     gpsDMS = num1 + "°" + num2 + "′" + num3 + "″";
    // } else {
    //     let num1 = tempStrArray[0];
    //     gpsDMS = num1 ? num1 + "°" + "0" + "′" + "0" + "″" : num1;
    // }
    // console.log(unit + gpsDMS, "unit + gpsDM")
    // return unit + gpsDMS;
    const degree = Math.floor(duStr);
    const minuteFloat = (duStr - degree) * 60;
    const minute = Math.floor(minuteFloat);
    const second = (minuteFloat - minute) * 60;
    return `${degree}°${minute}′${second.toFixed(6)}″`;
}
export function dmsToDecimal(dms) {
    // 使用正则表达式从输入字符串中提取度、分和秒的部分
    var regex = /^(\d+)°([\d.]+)′([\d.]+)″$/;
    var matches = dms.match(regex);

    if (!matches) {
        return null; // 如果输入不符合格式，则返回null或其他适当的错误值
    }

    var degrees = parseFloat(matches[1]);
    var minutes = parseFloat(matches[2]);
    var seconds = parseFloat(matches[3]);

    // 将度、分、秒转换为度的十进制值
    var decimalDegrees = degrees + minutes / 60 + seconds / 3600;

    return decimalDegrees;
}
export function duToGpsDM(duStr) {
    // duStr = duStr.toLowerCase();
    // duStr = duStr.replace(/\s+/g, ""); 
    // let strLength = duStr.length; 
    // let tempString = "";
    // let tempStrArray = new Array();
    // let tempCount = 0;
    // let tempPointFlag = 0;
    // let gpsDM;
    // let unit = ""
    // if(duStr.indexOf("-") != -1) {
    //     unit = "-"
    // }
    // for (let i = 0; i <= strLength; i++) {
    //     if (duStr[i] >= '0' && duStr[i] <= '9') {
    //         tempString += duStr[i];
    //         continue;
    //     } else if (duStr[i] == '.') {
    //         tempStrArray[tempCount] = tempString;
    //         tempString = "";
    //         tempCount++;
    //         tempStrArray[tempCount] = '.';
    //         tempPointFlag = 1;
    //         tempCount++;
    //     } else if (tempString.length > 0) {
    //         tempStrArray[tempCount] = tempString;
    //         tempString = "";
    //         tempCount++;
    //     }
    // }
    // if (tempPointFlag == 1) {
    //     let num1 = tempStrArray[0];
    //     let num2 = (parseFloat('0' + tempStrArray[1] + tempStrArray[2], 10) * 60).toFixed(7);
    //     // let num2 = tool.toFixedFn(parseFloat('0' + tempStrArray[1] + tempStrArray[2], 10) * 60, 7)
    //     gpsDM = num1 + "°" + num2 + "′";
    // } else {
    //     /**
    //      * 输入整数位
    //      */
    //     let num1 = tempStrArray[0];
    //     gpsDM = num1 ? num1 + "°" + "0" + "′" : "";
    // }
    // console.log(unit + gpsDM, "unit + gpsDM")
    // return unit + gpsDM;
    const degree = Math.floor(duStr);
    const minute = (duStr - degree) * 60;
    // console.log(`${degree}°${minute.toFixed(8)}'`, "unit + gpsDM")
    return `${degree}°${minute.toFixed(6)}′`;
}
