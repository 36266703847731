<template>
  <custom-dialog
    :show="show"
    :title="$t('label.center.editPwd')"
    width="500"
    @toggleShow="handleClose"
  >
    <div class="flex form" slot="main">
      <el-form ref="form" :model="form" label-width="108px" :rules="rules">
        <el-form-item :label="$t('label.center.oldPwd') + '：'" prop="oldPwd">
          <el-input
            size="small"
            v-model="form.oldPwd"
            show-password
            maxlength="15"
            :placeholder="$t('label.center.oldPwd')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.center.newPwd') + '：'" prop="newPwd">
          <el-input
            size="small"
            v-model="form.newPwd"
            show-password
            maxlength="15"
            :placeholder="$t('label.center.newPwd')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.center.surePwd') + '：'" prop="surePwd">
          <el-input
            size="small"
            show-password
            maxlength="15"
            v-model="form.surePwd"
            :placeholder="$t('label.center.surePwd')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="submit">
        {{ $t("dialog.userList.dispose") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import { editPwd } from "@/api/index.js";
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "ReceiverAdminR51EditPwd",
  components: {
    CustomDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 校验原密码
    var validateOldPwd = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]" // 特殊符号校验
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g"); // 汉字校验
      if (!value) {
        callback(new Error(this.$t("label.center.oldPassword")));
      } else if (value.length < 6) {
        callback(new Error(this.$t("label.center.pwdLength")));
      } else {
        // 接口查询原密码
        // if(res.data.code == 200){
        callback();
        // }
      }
    };
    // 校验密码
    var validatePass = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error(this.$t("label.center.newPassword")));
      } else if (hz.test(value) || fh.test(value)) {
        console.log(value);
        callback(new Error(this.$t("label.center.numAndLetter")));
      } else {
        // if (this.form.repassword !== "") {
        //   this.$refs.form.validateField("repassword");
        // }
        callback();
      }
    };
    // 校验确认密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("label.center.again")));
      } else if (value !== this.form.newPwd) {
        callback(new Error(this.$t("label.center.notSame")));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldPwd: "",
        newPwd: "",
        surePwd: "",
      },
      rules: {
        oldPwd: [
          { required: true, validator: validateOldPwd, trigger: "blur" },
        ],
        newPwd: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        surePwd: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    handleClose(done) {
      this.$emit("toggleShow", false);
      this.$refs["form"].resetFields();
    },
    handleChange(value) {
      console.log(value);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 校验通过
          let param = {
            password_old: this.form.oldPwd,
            password_new: this.form.newPwd,
            password_check: this.form.surePwd
          };
          // console.log(param, "pppp")
          editPwd(param).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: this.$t("label.center.successPwd"),
              });
            } 
            // else {
            //   this.$message({
            //     type: "error",
            //     message: "原密码输入错误，请重新输入",
            //   });
            // }
          });
          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
::v-deep {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
}
</style>