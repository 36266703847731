<template>
  <div class="popup">
    <div class="title">{{ lang.info }}</div>
    <div class="info">
      <div class="info_item flex">
        <div>{{ lang.model }}</div>
        <div>{{ data.model == 1 ? "R12" : "R51"}}</div>
      </div>
      <div class="info_item flex">
        <div>{{ lang.num }}</div>
        <div>{{ data.sn }}</div>
      </div>
      <div class="info_item flex">
        <div>{{ lang.belong }}</div>
        <div>{{ data.name }}</div>
      </div>
      <div class="info_item flex">
        <div>{{ lang.status }}</div>
        <div>{{ data.status == 1 ? lang.online : lang.offline }}</div>
      </div>
      <div class="info_item flex">
        <div>{{ lang.time }}</div>
        <div>{{ data.time }}</div>
      </div>
      <div class="flex" style="justify-content: center; margin-top: 10px">
        <el-button :disabled="data.status != 1" size="small" type="primary" @click="goReceiverInfo">
          {{ lang.goReveicerInfo }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "MapPopup",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    lang: {
      type: Object,
      default: null,
    },
    test: {
      type: Function,
      default: () => {
        return;
      },
    },
  },
  mounted() {
    // console.log(this.data,this.lang, "弹窗中的数据")
  },
  methods: {
    goReceiverInfo() {
      sessionStorage.setItem("currentSn", this.data.sn);
      // router.push({
      //   name: "info", // 跳转至接收机信息
      //   params: {
      //     test: 111,
      //   },
      // });
      let page = router.resolve({
        name: "info", // 路由名称
        query: { id: 222, e: 0 }, // 传递参数
      });
      // sessionStorage.setItem("currentSn", e.sn)
      // window.open(page.href + "?type=config", "_blank");
      window.open(page.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  width: 200px;
  height: 200px;
  padding: 0px 10px 10px 10px;
  .title {
    // line-height: 26px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    // background-color: #eeee;
  }
  .info {
    // margin-top: 20px;
    .info_item {
      line-height: 28px;
      justify-content: space-between;
    }
  }
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
.el-button--primary.is-disabled{
  background-color: #c1c4cb;
}
</style>