<template>
  <!-- 工作模式 -->
  <div class="container">
    <!-- <div class="title">{{ $t("routes.mode_new") }}</div> -->
    <div class="fw600">{{ $t("label.mode_new.title") }}</div>
    <el-select
      v-model="value"
      placeholder="请选择"
      size="small"
      class="mgt10"
      @change="handlerChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-form ref="formm" :model="form" label-width="110px" :rules="rules" style="width: 500px;">
      <div v-if="value != '2'">
        <div class="mgt10 fw600">
          {{ $t("label.mode_new.positionSet") }}
        </div>
        <el-form-item :label="$t('label.mode_new.style')" prop="style">
          <el-select
            v-model="form.style"
            placeholder="请选择"
            size="small"
            style="width:100%;"
            @change="styleChange"
          >
            <el-option
              v-for="item in $t('option.model.lonlat_format_options')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.lng')" prop="lon">
          <!-- <el-input
            size="small"
            maxlength="15"
            v-model="form.lng"
          ></el-input> -->
          <latlng-formart
            ref="longitude"
            :value.sync="form.lon"
            type="lon"
            :types="form.style"
          ></latlng-formart>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.lat')" prop="lat">
          <!-- <el-input
            size="small"
            maxlength="15"
            v-model="form.lat"
          ></el-input> -->
          <latlng-formart
            ref="latitude"
            :value.sync="form.lat"
            type="lat"
            :types="form.style"
          ></latlng-formart>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.hight')" prop="hight">
          <div class="flex">
            <el-input size="small" v-model="form.hight">
              <span slot="append">m</span>
            </el-input>
            <el-button
              class="mgl10"
              size="small"
              type="primary"
              @click="getLocation"
              >{{ $t("label.mode_new.getLocation") }}</el-button
            >
          </div>
        </el-form-item>
      </div>
      <div class="mgt10 fw600">{{ $t("label.mode_new.dataLineSetting") }}</div>
      <el-form-item
        :label="$t('label.mode_new.diffData')"
        v-if="value == 1"
        prop="diffData"
      >
        <el-select
          v-model="form.diffData"
          placeholder="请选择"
          size="small"
          style="width:100%;"
        >
          <el-option
            v-for="item in $t('option.model.diff_data_style')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.dataLine')" prop="dataLine">
        <el-select
          v-model="form.dataLine"
          placeholder="请选择"
          size="small"
          style="width:100%;"
        >
          <el-option
            v-for="item in $t('option.model.build_in_network')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.protocols')" prop="protocols">
        <el-select
          v-model="form.protocols"
          placeholder="请选择"
          size="small"
          style="width:100%;"
        >
          <el-option
            v-for="item in $t('option.model.transport_protocols')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.IP')" prop="IP" :key="'ip'">
        <el-input
          size="small"
          maxlength="15"
          v-model="form.IP"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.port')" prop="port">
        <el-input
          size="small"
          maxlength="5"
          v-model="form.port"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.username')"
        v-if="form.protocols == '2'"
        prop="username"
      >
        <el-input
          size="small"
          maxlength="15"
          v-model="form.username"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.password')"
        v-if="form.protocols == '2'"
        prop="password"
      >
        <el-input
          size="small"
          maxlength="15"
          show-password
          v-model="form.password"
        ></el-input>
      </el-form-item>
      <!-- 接入点设置 -->
      <el-form-item
        :label="$t('label.mode_new.pointSet')"
        v-if="form.protocols == '2' && value == 1"
        prop="pointSet"
      >
        <el-input
          size="small"
          maxlength="15"
          v-model="form.pointSet"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.pointSet')"
        v-if="form.protocols == '2' && value == 2"
        prop="pointSet2"
      >
        <div class="flex">
          <el-select v-model="form.pointSet2" placeholder="请选择" size="small" style="width:100%;">
            <el-option
              v-for="item in pointSetOpitons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="small"
            type="primary"
            class="mgl10"
            @click="getPointSet"
            >{{ $t("label.mode_new.getPoint") }}</el-button
          >
        </div>
      </el-form-item>
      <!-- <div class="mgt10 fw600" v-if="value == '2'">
        {{ $t("label.mode_new.limit") }}
      </div>
      <el-form-item
        :label="$t('label.mode_new.PDOP')"
        v-if="value == '2'"
        prop="PDOP"
      >
        <el-input
          size="small"
          v-model="form.PDOP"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.limit_diff_age')"
        v-if="value == '2'"
        prop="diff_age"
      >
        <el-input size="small" v-model="form.diff_age">
          <span slot="append">s</span>
        </el-input>
      </el-form-item> -->
    </el-form>
    <el-button size="small" type="primary" class="mgt10" @click="save">{{
      $t("label.mode_new.save")
    }}</el-button>
  </div>
</template>

<script>
import {
  configWorkMode,
  getWorkInfo,
  receiverInfo,
  getAccessPoint,
} from "@/api/index";
import * as gps from "../../utils/gpsToDu";
import {
  validIp,
  validPositiveInteger,
  validAll,
  validValueNumAndLetter,
  validDFMLng,
  validDFMLat,
  validDFLng,
  validDFLat,
  validLat,
  validLng,
} from "../../utils/regExp";
import LatlngFormart from "./component/latlngFormart.vue";
export default {
  name: "ModeNew",
  components: {
    LatlngFormart,
  },
  data() {
    // 校验IP
    const validateIP = (rule, value, callback) => {
      const ip =
        // /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
        // /^(\d{3}\.){3}\d{3}$/;
        /^((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;
      if (!value) {
        return callback(new Error(this.$t("label.mode_new.IPAddress")));
      } else if (value && !ip.test(value)) {
        return callback(new Error(this.$t("label.mode_new.IPCurrect")));
      } else {
        value.split(".").forEach((item) => {
          if (Number(item) > 255) {
            return callback(new Error(this.$t("label.mode_new.IPCurrect")));
          } else {
            callback();
          }
        });
      }
    };
    // 校验端口号
    const validatePort = (rule, value, callback) => {
      const port = /^\d{1,}$/;
      if (!port.test(value)) {
        return callback(new Error(this.$t("label.mode_new.portNum")));
      } else if (Number(value) > 65535 || Number(value) < 1) {
        return callback(new Error(this.$t("label.mode_new.portRange")));
      } else {
        callback();
      }
    };
    // 用户名称
    const validateName = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error(this.$t("label.mode_new.userName")));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error(this.$t("label.mode_new.numAndLetter")));
      } else {
        callback();
      }
    };
    // 校验密码
    const validatePass = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error(this.$t("label.mode_new.pwd")));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error(this.$t("label.mode_new.numAndLetter")));
      } else if (!value.length || value.length > 255) {
        callback(new Error(this.$t("label.mode_new.pwdLength")));
      } else {
        callback();
      }
    };
    // 接入点设置
    const validatePointSet = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      const hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (value.length > 15) {
        callback(new Error(this.$t("label.mode_new.pointSetLength")));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error(this.$t("label.mode_new.numAndLetterAnd_")));
      } else {
        callback();
      }
    };
    // 海拔高
    const elevation = (rule, value, callback) => {
      if (Number(value) >= -9999.999 && Number(value) <= 9999.999) {
        callback();
      } else {
        return callback(new Error(this.$t("label.mode_new.elevation")));
      }
    };
    // pdop
    const pdop = (rule, value, callback) => {
      const pdop = /^[0-9]\d*$/;
      if (!pdop.test(value) || value < 0 || value > 99 || !value) {
        return callback(new Error(this.$t("label.mode_new.pdop")));
      } else {
        callback();
      }
    };
    // 差分龄期
    const diff = (rule, value, callback) => {
      const diff = /^[1-9]\d*$/;
      if (!diff.test(value) || value < 1 || value > 600 || !value) {
        return callback(new Error(this.$t("label.mode_new.diff")));
      } else {
        callback();
      }
    };
    return {
      options: [
        {
          value: "1",
          label: "基准站",
        },
        {
          value: "2",
          label: "移动站",
        },
      ],
      check: 0,
      initMode: "", // 暂存工作模式初始中间变量
      pointSetOpitons: [], // 接入点设置下拉
      value: "1", // 基准站模式 移动站模式
      form: {
        style: "1", // 经纬度格式
        lon: "0", // 经度
        lat: "0", // 纬度
        hight: "0", // 海拔高
        diffData: "1", // 差分数据格式
        dataLine: "1", // 数据链
        protocols: "2", // 传输协议
        IP: "",
        port: "",
        username: "",
        password: "",
        pointSet: "", // 基准站-接入点设置
        pointSet2: "", // 移动站-接入点设置
        PDOP: "", // PDOP限制
        diff_age: "", // 限制差分龄期
      },
      originalLng: "", // 原始经度
      originalLat: "", // 原始纬度
      rules: {
        IP: [{ required: true, validator: validateIP, trigger: "blur" }],
        port: [{ required: true, validator: validatePort, trigger: "blur" }],
        username: [
          { required: true, validator: validateName, trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        pointSet: [
          { required: true, validator: validatePointSet, trigger: "blur" },
        ],
        lon: [
          { required: true, message: this.$t("label.mode_new.lon") },
          {
            validator: (rule, value, callback) => {
              let temp = value;
              if (this.form.style == 1) {
                if(!value && value != 0){
                  callback(new Error(this.$t("label.mode_new.lon")));
                } else if (!validLng(value)) {
                  callback(new Error(this.$t("label.mode_new.lonCurrect")));
                }
              } else if (this.form.style == 2) {
                temp = gps.gpsToDu(temp)[1];
                if (!validLng(temp) || !validDFLng(value)) {
                  callback(new Error(this.$t("label.mode_new.lonCurrect")));
                }
              } else if (this.form.style == 3) {
                temp = gps.gpsToDu(temp)[1];
                if (!validLng(temp) || !validDFMLng(value)) {
                  callback(new Error(this.$t("label.mode_new.lonCurrect")));
                }
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        lat: [
					{ required: true, message: this.$t("label.mode_new.lati") },
					{
						validator: (rule, value, callback) => {
							let temp = value;
							if (this.form.style == 1) {
                if(!value && value != 0){
                  callback(new Error(this.$t("label.mode_new.lati")));
                } else if (!validLat(value)) {
									callback(new Error(this.$t("label.mode_new.latCurrect")));
								}
							} else if (this.form.style == 2) {
								temp = gps.gpsToDu(temp)[1];
								if (!validLat(temp) || !validDFLat(value)) {
									callback(new Error(this.$t("label.mode_new.latCurrect")));
								}
							} else if (this.form.style == 3) {
								temp = gps.gpsToDu(temp)[1];
								if (!validLat(temp) || !validDFMLat(value)) {
									callback(new Error(this.$t("label.mode_new.latCurrect")));
								}
							}
							callback();
						}, trigger: 'blur'
					}
				],
        hight: [{ required: true, validator: elevation, trigger: "blur" }],
        pointSet2: [
          { required: true, message: this.$t("label.mode_new.point"), trigger: "change" },
        ],
        PDOP: [{ required: true, validator: pdop, trigger: "blur" }],
        diff_age: [{ required: true, validator: diff, trigger: "blur" }],
      },
    };
  },

  activated() {
    this.getInitWork();
  },
  methods: {
    // 获取工作模式位置信息
    getLocation() {
      receiverInfo({ sn: sessionStorage.getItem("currentSn"), model: 2 }).then((res) => {
        if (res.code == 200) {
          this.form.lat = res.data.lat + "";
          this.form.lon = res.data.lon + "";
          this.form.hight = res.data.height;
          this.originalLng = res.data.lon;
          this.originalLat = res.data.lat;
          this.handleLonLat(res.data)
        }
      });
    },
    handleLonLat(val) {
      let unitLon = ""
      let unitLat = ""
      console.log(val, "vallll???")
			let longitude = val?.lon + "" || this.form.lon || '';
			let latitude = val?.lat + "" || this.form.lat || '';
      if(longitude.indexOf("-") != -1){
        unitLon = "-"
      }
      if(latitude.indexOf("-") != -1){
        unitLat = "-"
      }
			let isDu = true;
			if (!longitude || !latitude) {
				return;
			}
			isDu = isDu && validLng(longitude) && validLat(latitude);
			if (isDu) {
				longitude += '';
				latitude += ''
			}
			switch (this.form.style) {
				case '1':
					if (!isDu) {
						longitude = gps.gpsToDu(longitude)[1].toString();
						latitude = gps.gpsToDu(latitude)[1].toString()
					}
					break;
				case '2':
					if (!isDu) {
						longitude = gps.gpsToDu(longitude)[1].toString();
						latitude = gps.gpsToDu(latitude)[1].toString();
					}
					longitude = gps.duToGpsDM(longitude);
					latitude = gps.duToGpsDM(latitude);
					break;
				case '3':
					if (!isDu) {
						longitude = gps.gpsToDu(longitude)[1].toString();
						latitude = gps.gpsToDu(latitude)[1].toString();
					}
					longitude = gps.duToGpsDMS(longitude);
					latitude = gps.duToGpsDMS(latitude);
					break;
				default:
					break;
			}
			this.form.lon = longitude;
			this.form.lat = latitude;
			this.$nextTick(() => {
				this.$refs.longitude.setValue(longitude);
				this.$refs.latitude.setValue(latitude);
			})
		},
    // 获取工作模式初始信息
    getInitWork() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
      };
      getWorkInfo(param).then((res) => {
        //
        if (res.code == 200 && res.data) {
          // this.handleLonLat(res.data)
          this.initMode = res.data.mode == 0 ? 1 + "" : res.data.mode + ""; // 暂存初始中间变量
          this.value = res.data.mode == 0 ? 1 + "" : res.data.mode + "";
          this.form.lon = res.data.lon + "";
          this.form.lat = res.data.lat + "";
          this.form.hight = res.data.height;
          // this.form.diffData = res.data.delta_data_format + "";
          this.form.diffData = "1"; // 只有3.X
          this.form.dataLine = res.data.data_link + "";
          this.form.protocols = res.data.protocol + "";
          this.form.IP = res.data.ip;
          this.form.port = res.data.port;
          this.form.username = res.data.account;
          this.form.password = res.data.password;
          // 不需要获得mode2接入点设置初始变量 —— 2024/2/23 更改为都需要获取回显初始值
          if (res.data.mode == 1) {
            // 基准站
            this.handleLonLat(res.data)
            this.form.pointSet = res.data.access_point[0];
            // console.log(this.form.pointSet, res.data.access_point);
          } else {
            // 移动站接入点设置回显 
            this.form.pointSet2 = res.data.access_point[0];
          }
          // else if (res.data.mode == 1 && res.data.protocol == 2) {
          //   this.form.pointSet2 = res.data.access_point;
          // }
          this.form.PDOP = res.data.pdop;
          // this.form.diff_age = res.data.cflq;
          this.form.diff_age = res.data.cflg;
        }
      });
    },
    // 设置工作模式
    setWorkMode() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        mode: Number(this.value),
        lon: this.form.style == 1 ? Number(this.form.lon) : Number(gps.gpsToDu(this.form.lon + "")[1].toString()),
        lat: this.form.style == 1 ? Number(this.form.lat) : Number(gps.gpsToDu(this.form.lat + "")[1].toString()),
        height: Number(this.form.hight),
        data_link: Number(this.form.dataLine),
        delta_data_format: Number(this.form.diffData),
        protocol: Number(this.form.protocols),
        ip: this.form.IP,
        port: this.form.port,
        account: this.form.username,
        password: this.form.password,
        access_point:
          this.value == 1 ? this.form.pointSet : this.form.pointSet2,
        // pdop: Number(this.form.PDOP),
        // cflq: Number(this.form.diff_age),
        pdop: 3,
        cflq: 20,
      };
      // console.log(param, "ppppp");
      configWorkMode(param).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: this.$t("label.mode_new.setSuccess"),
          });
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    // 度分秒转换 默认都转换成度
    styleChange(e) {
      // let param = {
      //   lon: this.form.lon + "",
      //   lat: this.form.lat + ""
      // }
      // this.handleLonLat(param)
      this.form.lon = this.form.lon + ""
      this.form.lat = this.form.lat + ""
      this.handleLonLat(this.form)
      // this.form.lng = "";
      // this.form.lat = "";
      // this.$nextTick(() => {
      //   this.$refs.longitude.setValue(this.form.lng + "");
      //   this.$refs.latitude.setValue(this.form.lat + "");
      // });
    },
    handlerChange() {
      // this.form.protocols = "0";
      (this.form = {
        style: "1", // 经纬度格式
        lon: "", // 经度
        lat: "", // 纬度
        hight: 0, // 海拔高
        diffData: "1", // 差分数据格式
        dataLine: "1", // 数据链
        protocols: "2", // 传输协议
        IP: "",
        port: "",
        username: "",
        password: "",
        pointSet: "", // 基准站-接入点设置
        pointSet2: "", // 移动站-接入点设置
        PDOP: "", // PDOP限制
        diff_age: "", // 限制差分龄期
      }),
        // this.$nextTick(() => {
        // this.$refs['formm'].resetFields()
        this.$refs["formm"].clearValidate();
      if (this.initMode == this.value) {
        // 基准站和移动站切换时 如果切换到接口返回的状态就获取数据
        this.getInitWork();
      }
      // });
    },
    // 获取接入点
    getPointSet() {
      let checkArr = ["IP", "port"];
      this.$refs.formm.validateField(checkArr, (valid) => {
        this.check++;
        // console.log(valid, "校验失败提示");
        if (valid) {
          this.check = 0
          // console.log("校验失败");
          return;
        } else {
          if(this.check !== 2){
            return
          }
          // console.log(this.check, "校验通过")
          this.$message({
            type: "info",
            message: "正在获取接入点...",
          });
          let param = {
            sn: sessionStorage.getItem("currentSn"),
            ip: this.form.IP,
            port: this.form.port,
          };
          this.check = 0;
          getAccessPoint(param).then((res) => {
            // console.log(res, "rrrr");
            if (res.code == 200) {
              this.pointSetOpitons = res.data.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              });
              this.$message({
                type: "success",
                message: "获取成功",
              });
            }
          });
        }
      });
    },
    save() {
      this.$refs["formm"].validate((valid) => {
        if (valid) {
          this.setWorkMode();
        } else {
          return false;
        }
      });
    },
    // // 格式化IP为 000.000.000.000
    // formatIP() {
    //   let ipArr = this.form.IP.split(".").forEach((item, index) => {
    //     if (item.length == 1) {
    //       ipArr[index] = "00" + item;
    //     } else if (item.length == 2) {
    //       ipArr[index] = "0" + item;
    //     }
    //   });
    //   this.form.IP = ipArr.join(".");
    // },
    // // 反格式化
    // formatIPBack(ip) {
    //   ip = ip
    //     .split(".")
    //     .map((item) => {
    //       if (item.startsWith("00")) {
    //         return item.slice(2);
    //       }
    //       if (item.startsWith("0")) {
    //         return item.slice(1);
    //       } else {
    //         return item;
    //       }
    //     })
    //     .join(".");
    //   return ip;
    // },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  overflow: auto;
}
.mgt10 {
  margin-top: 10px;
}
.mgl10 {
  margin-left: 10px;
}
.minW {
  min-width: 110px;
}
.fw600 {
  font-weight: 600;
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
</style>