<template>
  <!-- 工作模式 -->
  <div class="container">
    <!-- <div class="title">{{ $t("routes.mode_new") }}</div> -->
    <div class="fw600">{{ $t("label.mode_new.title") }}</div>
    <el-select
      v-model="value"
      placeholder="请选择"
      size="small"
      class="mgt10"
      @change="handlerChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-form ref="formm" :model="form" label-width="110px" :rules="rules">
      <div v-if="value != '2'">
        <div class="mgt10 fw600">
          {{ $t("label.mode_new.positionSet") }}
        </div>
        <el-form-item :label="$t('label.mode_new.style')" prop="style">
          <el-select
            v-model="form.style"
            placeholder="请选择"
            size="small"
            style="width: 250px"
            @change="styleChange"
          >
            <el-option
              v-for="item in $t('option.model.lonlat_format_options')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.lng')" prop="lng">
          <el-input
            style="width: 250px"
            size="small"
            maxlength="15"
            v-model="form.lng"
            @change="lngChange"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.lat')" prop="lat">
          <el-input
            size="small"
            style="width: 250px"
            maxlength="15"
            v-model="form.lat"
            @change="latChange"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.mode_new.hight')" prop="hight">
          <div class="flex">
            <el-input size="small" style="width: 250px" v-model="form.hight">
              <span slot="append">m</span>
            </el-input>
            <el-button
              class="mgl10"
              size="small"
              type="primary"
              @click="getLocation"
              >{{ $t("label.mode_new.getLocation") }}</el-button
            >
          </div>
        </el-form-item>
      </div>
      <div class="mgt10 fw600">{{ $t("label.mode_new.dataLineSetting") }}</div>
      <el-form-item
        :label="$t('label.mode_new.diffData')"
        v-if="value == 1"
        prop="diffData"
      >
        <el-select
          v-model="form.diffData"
          placeholder="请选择"
          size="small"
          style="width: 250px"
        >
          <el-option
            v-for="item in $t('option.model.diff_data_style')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.dataLine')" prop="dataLine">
        <el-select
          v-model="form.dataLine"
          placeholder="请选择"
          size="small"
          style="width: 250px"
        >
          <el-option
            v-for="item in $t('option.model.build_in_network')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.protocols')" prop="protocols">
        <el-select
          v-model="form.protocols"
          placeholder="请选择"
          size="small"
          style="width: 250px"
        >
          <el-option
            v-for="item in $t('option.model.transport_protocols')"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.IP')" prop="IP" :key="'ip'">
        <el-input
          size="small"
          style="width: 250px"
          maxlength="15"
          v-model="form.IP"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('label.mode_new.port')" prop="port">
        <el-input
          size="small"
          style="width: 250px"
          maxlength="5"
          v-model="form.port"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.username')"
        v-if="form.protocols == '2'"
        prop="username"
      >
        <el-input
          size="small"
          style="width: 250px"
          maxlength="15"
          v-model="form.username"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.password')"
        v-if="form.protocols == '2'"
        prop="password"
      >
        <el-input
          size="small"
          style="width: 250px"
          maxlength="15"
          show-password
          v-model="form.password"
        ></el-input>
      </el-form-item>
      <!-- 接入点设置 -->
      <el-form-item
        :label="$t('label.mode_new.pointSet')"
        v-if="form.protocols == '2' && value == 1"
        prop="pointSet"
      >
        <el-input
          size="small"
          style="width: 250px"
          maxlength="15"
          v-model="form.pointSet"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.pointSet')"
        v-if="form.protocols == '2' && value == 2"
        prop="pointSet2"
      >
        <div class="flex">
          <el-select v-model="form.pointSet2" placeholder="请选择" size="small">
            <el-option
              v-for="item in pointSetOpitons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            size="small"
            type="primary"
            class="mgl10"
            @click="getPointSet"
            >{{ $t("label.mode_new.getPoint") }}</el-button
          >
        </div>
      </el-form-item>
      <div class="mgt10 fw600" v-if="value == '2'">
        {{ $t("label.mode_new.limit") }}
      </div>
      <el-form-item
        :label="$t('label.mode_new.PDOP')"
        v-if="value == '2'"
        prop="PDOP"
      >
        <el-input
          size="small"
          style="width: 250px"
          v-model="form.PDOP"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('label.mode_new.limit_diff_age')"
        v-if="value == '2'"
        prop="diff_age"
      >
        <el-input size="small" style="width: 250px" v-model="form.diff_age">
          <span slot="append">s</span>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button size="small" type="primary" class="mgt10" @click="save">{{
      $t("label.mode_new.save")
    }}</el-button>
  </div>
</template>

<script>
import {
  configWorkMode,
  getWorkInfo,
  receiverInfo,
  getAccessPoint,
} from "@/api/index";
export default {
  name: "ModeNew",

  data() {
    // 校验IP
    const validateIP = (rule, value, callback) => {
      const ip =
        // /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
        // /^(\d{3}\.){3}\d{3}$/;
        /^((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;
      if (!value) {
        return callback(new Error("请输入IP地址"));
      } else if (value && !ip.test(value)) {
        return callback(new Error("请输入正确的IP地址"));
      } else {
        value.split(".").forEach((item) => {
          if (Number(item) > 255) {
            return callback(new Error("请输入正确的IP地址"));
          } else {
            callback();
          }
        });
      }
    };
    // 校验端口号
    const validatePort = (rule, value, callback) => {
      const port = /^\d{1,}$/;
      if (!port.test(value)) {
        return callback(new Error("请输入正确的端口号"));
      } else if (Number(value) > 65535 || Number(value) < 1) {
        return callback(new Error("端口号范围是1-65535"));
      } else {
        callback();
      }
    };
    // 用户名称
    const validateName = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error("请输入用户名"));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error("请输入数字或字母"));
      } else {
        callback();
      }
    };
    // 校验密码
    const validatePass = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error("请输入数字或字母"));
      } else if (!value.length || value.length > 255) {
        callback(new Error("密码长度为1~255位"));
      } else {
        callback();
      }
    };
    // 接入点设置
    const validatePointSet = (rule, value, callback) => {
      const fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      const hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (value.length > 15) {
        callback(new Error("接入点长度为15位"));
      } else if (hz.test(value) || fh.test(value)) {
        callback(new Error("请输入数字、字母或下划线"));
      } else {
        callback();
      }
    };
    // 经度
    const longitude = (rule, value, callback) => {
      // console.log(value)
      // if (Number(value) >= 0 && Number(value) <= 180) {
      //   callback();
      // } else {
      //   return callback(new Error("请输入0-180之间的数值！"));
      // }
      if (Number(this.originalLng) >= 0 && Number(this.originalLng) <= 180) {
        callback();
      } else {
        return callback(new Error("请输入0-180之间的数值！"));
      }
    };
    // 纬度
    const latitude = (rule, value, callback) => {
      // console.log(value)
      // if (Number(value) >= 0 && Number(value) <= 90) {
      //   callback();
      // } else {
      //   return callback(new Error("请输入0-90之间的数值！"));
      // }
      if (Number(this.originalLat) >= 0 && Number(this.originalLat) <= 90) {
        callback();
      } else {
        return callback(new Error("请输入0-90之间的数值！"));
      }
    };
    // 海拔高
    const elevation = (rule, value, callback) => {
      // console.log(value)
      if (Number(value) >= -9999.999 && Number(value) <= 9999.999) {
        callback();
      } else {
        return callback(new Error("请输入-9999.999~9999.999之间的数值！"));
      }
    };
    // pdop
    const pdop = (rule, value, callback) => {
      const pdop = /^[0-9]\d*$/
      if (!pdop.test(value) || value < 0 || value > 99 || !value) {
        return callback(new Error("请输入0-99正整数"));
      } else {
        callback();
      }
    };
    // 差分龄期
    const diff = (rule, value, callback) => {
      const diff =  /^[1-9]\d*$/
      if (!diff.test(value) || value < 1 || value > 600 || !value) {
        return callback(new Error("请输入1~600正整数"));
      } else {
        callback();
      }
    };
    return {
      options: [
        {
          value: "1",
          label: "基准站",
        },
        {
          value: "2",
          label: "移动站",
        },
      ],
      check: 0,
      initMode: "", // 暂存工作模式初始中间变量
      pointSetOpitons: [], // 接入点设置下拉
      value: "1", // 基准站模式 移动站模式
      form: {
        style: "1", // 经纬度格式
        lng: "0", // 经度
        lat: "0", // 纬度
        hight: "0", // 海拔高
        diffData: "1", // 差分数据格式
        dataLine: "1", // 数据链
        protocols: "2", // 传输协议
        IP: "",
        port: "",
        username: "",
        password: "",
        pointSet: "", // 基准站-接入点设置
        pointSet2: "", // 移动站-接入点设置
        PDOP: "", // PDOP限制
        diff_age: "", // 限制差分龄期
      },
      originalLng: "", // 原始经度
      originalLat: "", // 原始纬度
      rules: {
        IP: [{ required: true, validator: validateIP, trigger: "blur" }],
        port: [{ required: true, validator: validatePort, trigger: "blur" }],
        username: [
          { required: true, validator: validateName, trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        pointSet: [
          { required: true, validator: validatePointSet, trigger: "blur" },
        ],
        lng: [{ required: true, validator: longitude, trigger: "blur" }],
        lat: [{ required: true, validator: latitude, trigger: "blur" }],
        hight: [{ required: true, validator: elevation, trigger: "blur" }],
        pointSet2: [
          { required: true, message: "请选择接入点", trigger: "change" },
        ],
        PDOP: [{ required: true, validator: pdop, trigger: "blur" }],
        diff_age: [{ required: true, validator: diff, trigger: "blur" }],
      },
    };
  },

  activated() {
    this.getInitWork();
  },

  methods: {
    // 获取工作模式初始值
    getLocation() {
      receiverInfo({ sn: sessionStorage.getItem("currentSn"), model: 2 }).then((res) => {
        if (res.code == 200) {
          this.form.lat = res.data.lat + "";
          this.form.lng = res.data.lon + "";
          this.form.hight = res.data.height;
          this.originalLng = res.data.lon;
          this.originalLat = res.data.lat;
        }
      });
    },
    // 获取工作模式初始信息
    getInitWork() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
      };
      getWorkInfo(param).then((res) => {
        //
        if (res.code == 200 && res.data) {
          this.initMode = res.data.mode == 0 ? 1 + "" : res.data.mode + ""; // 暂存初始中间变量
          this.value = res.data.mode == 0 ? 1 + "" : res.data.mode + "";
          this.form.lng = res.data.lon;
          this.form.lat = res.data.lat;
          this.form.hight = res.data.height;
          this.form.diffData = res.data.delta_data_format + "";
          this.form.dataLine = res.data.data_link + "";
          this.form.protocols = res.data.protocol + "";
          this.form.IP = res.data.ip;
          this.form.port = res.data.port;
          this.form.username = res.data.account;
          this.form.password = res.data.password;
          // 不需要获得接入点设置初始变量
          if (res.data.mode == 1) {
            this.form.pointSet = res.data.access_point[0];
            // console.log(this.form.pointSet, res.data.access_point);
          } 
          // else if (res.data.mode == 1 && res.data.protocol == 2) {
          //   this.form.pointSet2 = res.data.access_point;
          // }
          this.form.PDOP = res.data.pdop;
          // this.form.diff_age = res.data.cflq;
          this.form.diff_age = res.data.cflg;
          this.originalLng = res.data.lon + "";
          this.originalLat = res.data.lat + "";
        }
      });
    },
    // 设置工作模式
    setWorkMode() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        mode: Number(this.value),
        lon: Number(this.form.lng),
        lat: Number(this.form.lat),
        // lon: Number(this.originalLng),
        // lat: Number(this.originalLat),
        height: Number(this.form.hight),
        data_link: Number(this.form.dataLine),
        delta_data_format: Number(this.form.diffData),
        protocol: Number(this.form.protocols),
        ip: this.form.IP,
        port: this.form.port,
        account: this.form.username,
        password: this.form.password,
        access_point:
          this.value == 1 ? this.form.pointSet : this.form.pointSet2,
        pdop: Number(this.form.PDOP),
        cflq: Number(this.form.diff_age),
      };
      console.log(param, "ppppp");
      configWorkMode(param).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "配置成功！",
          });
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    // 度分秒转换 默认都转换成度
    styleChange(e) {
      // console.log(e)
      if (
        (this.form.lng && this.form.lat) ||
        (this.form.lng == 0 && this.form.lat == 0)
      ) {
        if (e == "1") {
          // this.form.lng = this.originalLng;
          // this.form.lat = this.originalLat;

          const duLng = this.form.lng.split("°")[0];
          const fenLng = this.form.lng.split("°")[1].split("′")[0];
          const miaoLng = this.form.lng.split("°")[1].split("′")[1]
            ? this.form.lng.split("°")[1].split("′")[1].split("″")[0]
            : "";
          const duLat = this.form.lat.split("°")[0];
          const fenLat = this.form.lat.split("°")[1].split("′")[0];
          const miaoLat = this.form.lat.split("°")[1].split("′")[1]
            ? this.form.lng.split("°")[1].split("′")[1].split("″")[0]
            : "";
          // console.log(duLng, fenLng, miaoLng)
          this.form.lng = this.ToDu(duLng, fenLng, miaoLng, "6");
          this.form.lat = this.ToDu(duLat, fenLat, miaoLat, "6");
        } else if (e == "2") {
          this.form.lng = this.ToDMS("2", this.originalLng);
          this.form.lat = this.ToDMS("2", this.originalLat);
        } else if (e == "3") {
          this.form.lng = this.ToDMS("3", this.originalLng);
          this.form.lat = this.ToDMS("3", this.originalLat);
        }
      }
    },
    // 度分秒转度
    ToDu(strDu, strFen, strMiao, len) {
      console.log(strDu, strFen, strMiao);
      len = len > 6 || typeof len == "undefined" ? 6 : len; //精确到小数点后最多六位
      strDu =
        typeof strDu == "undefined" || strDu == "" ? 0 : parseFloat(strDu);
      strFen =
        typeof strFen == "undefined" || strFen == ""
          ? 0
          : parseFloat(strFen) / 60;
      strMiao =
        typeof strMiao == "undefined" || strMiao == ""
          ? 0
          : parseFloat(strMiao) / 3600;
      let digital = strDu + strFen + strMiao;
      if (digital == 0) {
        return 0;
      } else {
        return digital.toFixed(len);
      }
    },
    // 度转度分秒
    ToDMS(type, val) {
      val = val + ""; // 转成字符串
      if (typeof val == "undefined" || val == "") {
        return "";
      }
      let i = val.indexOf(".");
      let strDu = i < 0 ? val : val.substring(0, i); //获取度
      let strFen = 0; // 分
      let strMiao = 0; // 秒
      if (i > 0) {
        strFen = "0" + val.substring(i);
        strFen = strFen * 60 + "";
        i = strFen.indexOf(".");
        if (i > 0) {
          strMiao = "0" + strFen.substring(i);
          strFen = strFen.substring(0, i); //获取分
          strMiao = strMiao * 60 + "";
          // i = strMiao.indexOf(".");
          // strMiao = strMiao.substring(0, i + 4); //取到小数点后面三位
          strMiao = strMiao.substring(0, i); //取到小数点后面三位
          // strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
          // strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
        }
      }
      if (type == 2) {
        return strDu + "°" + strFen + "′";
      } else if (type == 3) {
        return strDu + "°" + strFen + "′" + strMiao + "″";
      }
    },
    handlerChange() {
      // this.form.protocols = "0";
      (this.form = {
        style: "1", // 经纬度格式
        lng: this.originalLng, // 经度
        lat: this.originalLat, // 纬度
        hight: 0, // 海拔高
        diffData: "1", // 差分数据格式
        dataLine: "1", // 数据链
        protocols: "2", // 传输协议
        IP: "",
        port: "",
        username: "",
        password: "",
        pointSet: "", // 基准站-接入点设置
        pointSet2: "", // 移动站-接入点设置
        PDOP: "", // PDOP限制
        diff_age: "", // 限制差分龄期
      }),
        // this.$nextTick(() => {
        // this.$refs['formm'].resetFields()
        this.$refs["formm"].clearValidate();
      if (this.initMode == this.value) {
        // 基准站和移动站切换时 如果切换到接口返回的状态就获取数据
        this.getInitWork();
      }
      // });
    },
    // 获取度度格式的经纬度
    lngChange(e) {
      if ((e.indexOf("°") || e.indexOf("′") || e.indexOf("″")) != -1) {
        const duLng = e.split("°")[0];
        const fenLng = e.split("°")[1].split("′")[0];
        const miaoLng = e.split("°")[1].split("′")[1]
          ? e.split("°")[1].split("′")[1].split("″")[0]
          : "";
        this.originalLng = this.ToDu(duLng, fenLng, miaoLng, "6");
      } else {
        this.originalLng = e;
      }
      // console.log(this.originalLng);
    },
    // 获取度度格式的经纬度
    latChange(e) {
      if ((e.indexOf("°") || e.indexOf("′") || e.indexOf("″")) != -1) {
        const duLat = e.split("°")[0];
        const fenLat = e.split("°")[1].split("′")[0];
        const miaoLat = e.split("°")[1].split("′")[1]
          ? e.split("°")[1].split("′")[1].split("″")[0]
          : "";
        this.originalLat = this.ToDu(duLat, fenLat, miaoLat, "6");
      } else {
        this.originalLat = e;
      }
      // console.log(this.originalLat);
    },
    // 获取接入点
    getPointSet() {
      let checkArr = ["IP", "port"];
      this.$refs.formm.validateField(checkArr, (valid) => {
        // this.check++;
        console.log(valid, "vvvv");
        if (valid) {
          console.log("校验失败");
          return;
        } else {
          this.check++;
          if (this.check !== 2) {
            this.check = 0
            return;
          }
          // console.log("校验通过")
          this.$message({
            type: "info",
            message: "正在获取接入点...",
          });
          let param = {
            sn: sessionStorage.getItem("currentSn"),
            ip: this.form.IP,
            port: this.form.port,
          };
          this.check = 0;
          getAccessPoint(param).then((res) => {
            console.log(res, "rrrr");
            if (res.code == 200) {
              this.pointSetOpitons = res.data.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              });
              this.$message({
                type: "success",
                message: "获取成功",
              });
            }
          });
        }
      });
    },
    save() {
      this.$refs["formm"].validate((valid) => {
        if (valid) {
          this.setWorkMode();
        } else {
          return false;
        }
      });
    },
    // 格式化IP为 000.000.000.000
    formatIP() {
      let ipArr = this.form.IP.split(".").forEach((item, index) => {
        if (item.length == 1) {
          ipArr[index] = "00" + item;
        } else if (item.length == 2) {
          ipArr[index] = "0" + item;
        }
      });
      this.form.IP = ipArr.join(".");
    },
    // 反格式化
    formatIPBack(ip) {
      ip = ip
        .split(".")
        .map((item) => {
          if (item.startsWith("00")) {
            return item.slice(2);
          }
          if (item.startsWith("0")) {
            return item.slice(1);
          } else {
            return item;
          }
        })
        .join(".");
      return ip;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  overflow: auto;
}
.mgt10 {
  margin-top: 10px;
}
.mgl10 {
  margin-left: 10px;
}
.minW {
  min-width: 110px;
}
.fw600 {
  font-weight: 600;
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
</style>