<template>
  <div class="container">
    <div class="container_name">{{ $t("label.nmea.title") }}</div>
    <div v-for="(item, index) in title" :key="index" class="container_item">
      <div class="container_title">{{ item }}：</div>
      <el-select v-model="form[item]" size="mini">
        <el-option
          v-for="item in filterOptions(item)"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="container_info">{{ $t(`label.nmea.${item}`) }}</div>
    </div>
    <div class="btn">
      <el-button type="primary" size="mini" @click="save">{{
        $t("label.nmea.btn")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { getNMEA, setNMEA } from "@/api/index";
export default {
  name: "ReceiverAdminR51Nmea",

  data() {
    return {
      title: [
        "GGA",
        "RMC",
        "GST",
        "GSA",
        "GSV",
        "GLL",
        "VTG",
        "ZDA",
        /****/
        // "DTM",
        // "GBS",
        // "GNS",
        // "GRS",
        // "THS",
        // "ROT",
      ],
      form: {
        GGA: "0",
        GLL: "0",
        GSA: "0",
        GST: "0",
        GSV: "0",
        RMC: "0",
        VTG: "0",
        ZDA: "0",
        /********/
        // DTM: "0",
        // GBS: "0",
        // GNS: "0",
        // GRS: "0",
        // THS: "0",
        // ROT: "0",
      },
    };
  },

  activated() {
    this.initNMEA();
  },

  methods: {
    filterOptions(type) {
      console.log(type, "ttttt");
      if (type == "GSA" || type == "GSV") {
        return this.$t("option.postSet.NMEA_options").filter(
          (item) =>
            item.value == "0" ||
            item.value == "1000" ||
            item.value == "5000" ||
            item.value == "2000"
        );
      } else {
        return this.$t("option.postSet.NMEA_options");
      }
    },
    initNMEA() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 2,
      };
      // 获取NMEA初始数据
      getNMEA(param).then((res) => {
        if (res.code == 200) {
          Object.keys(this.form).forEach((f) => {
            this.form[f] = res.data[f.toLowerCase()] + "";
          });
        }
      });
    },
    save() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        model: 2,
        gga: this.form.GGA,
        rmc: this.form.RMC,
        gll: this.form.GLL,
        gsa: this.form.GSA,
        gst: this.form.GST,
        gsv: this.form.GSV,
        vtg: this.form.VTG,
        zda: this.form.ZDA,
      };
      // console.log(param, "ppppppp")
      setNMEA(param).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: this.$t("label.nmea.nmea_success"),
          });
          // this.initNMEA()
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
      // console.log(this.form, "ffff");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  font-size: 14px;
  padding: 10px 20px;
  &_name {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 0;
  }
  &_title {
    min-width: 50px;
    // margin-right: 10px;
  }
  &_item {
    display: flex;
    align-items: center;
    line-height: 40px;
  }
  &_info {
    margin-left: 20px;
  }
  .btn {
    margin: 10px 0;
    .el-button {
      border: none;
    }
    .el-button--primary {
      background-color: #659b5d;
    }
  }
}
</style>