<template>
  <!-- 卫星信息 -->
  <div class="satellite">
    <el-button
      type="success"
      :class="{ normal: item.type != currentModel }"
      v-for="item in model"
      class="btn"
      :key="item.type"
      size="small"
      @click="handleModel(item.type)"
      >{{ item.label }}</el-button
    >

    <div class="satellite-table" v-show="currentModel == 'list'">
      <list
        :tableData="tableData"
        :active="currentModel"
        :satellites="satellites"
      ></list>
    </div>
    <div class="flex satellite-position" v-show="currentModel == 'position'">
      <position
        :tableData="tableData"
        :active="currentModel"
        :satellites="satellites"
      ></position>
    </div>
    <div class="satellite-chart" v-show="currentModel == 'chart'">
      <Chart :tableData="tableData" :active="currentModel"></Chart>
    </div>
  </div>
</template>

<script>
import { starsInfo } from "@/api/index";
import list from "./components/list.vue";
import Position from "./components/position.vue";
import Chart from "./components/chart.vue";
export default {
  components: {
    list,
    Position,
    Chart,
  },
  data() {
    return {
      tableData: [],
      satellites: [
        {
          label: "label.satellite.BDS",
          value: "BDS",
        },
        {
          label: "label.satellite.GPS",
          value: "GPS",
        },
        {
          label: "label.satellite.GLONASS",
          value: "GLONASS",
        },
        {
          label: "label.satellite.GALILEO",
          value: "GAL",
        },
        {
          label: "label.satellite.QZSS",
          value: "QZSS",
        },
        {
          label: "label.satellite.SBAS",
          value: "SBAS",
        },
      ],
      names: {
        GLONASS: "R",
        BDS: "C",
        GPS: "G",
        GAL: "E",
        SBSA: "S",
        QZSS: "J",
      },
      model: [
        {
          type: "list",
          label: this.$t("label.satellite.page_title"),
        },
        {
          type: "position",
          label: this.$t("label.satellite.stellar_map"),
        },
        {
          type: "chart",
          label: this.$t("label.satellite.SNR"),
        },
      ],
      currentModel: "list",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        // sn: "1111111111", // 测试sn
        tag: 1, // 目前写死 1
      };
      starsInfo(param).then((res) => {
        this.tableData = []
        console.log(res, "ressss");
        let sortArr = ["BDS", "GPS", "GLONASS", "GAL", "QZSS", "SBAS"];
        sortArr.forEach(item=>{
          res.data.forEach(it => {
            if(it.type == item){
              this.tableData.push({
                type: it.type,
                id: it.prn,
                hight_angle: it.height_angle,
                position_angle: it.azt,
                signal1: it.signal1 || "—",
                SNR1: it.ratio1 || "—",
                signal2: it.signal2 || "—",
                SNR2: it.ratio2 || "—",
                signal3: it.signal3 || "—",
                SNR3: it.ratio3 || "—",
                signal4: it.signal4 || "—",
                SNR4: it.ratio4 || "—",
                signal5: it.signal5 || "—",
                SNR5: it.ratio5 || "—",
                signal6: it.signal6 || "—", 
                SNR6: it.ratio6 || "—",
                label: this.names[it.type]
              })
            }
          });
        })
        console.log(this.tableData, "tabbb")
        // this.tableData = res.data.map((item) => {
        //   return {
        //     type: item.type,
        //     id: item.prn,
        //     hight_angle: item.height_angle,
        //     position_angle: item.azt,
        //     signal1: item.signal1,
        //     SNR1: item.ratio1,
        //     signal2: item.signal2,
        //     SNR2: item.ratio2,
        //     signal3: item.signal3,
        //     SNR3: item.ratio3,
        //     signal4: item.signal4,
        //     SNR4: item.ratio4,
        //     signal5: item.signal5,
        //     SNR5: item.ratio5,
        //     signal6: item.signal6,
        //     SNR6: item.ratio6,
        //     // label: this.names[]
        //   };
        // });
      });
    },
    handleModel(val) {
      if (this.currentModel != val) {
        this.currentModel = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.satellite {
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .btn {
    width: 70px;
    height: 30px;
    line-height: 30px;
    background-color: $main_color;
    border-color: $main_color;
    padding: 0;
    margin-top: 30px;
  }

  .normal {
    background-color: transparent;
    border-color: transparent;
    color: #333333;
  }

  &-radio {
    margin-top: 30px;
  }

  &-table {
    margin-top: 10px;
    // width: 1000px;
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  &-position {
    justify-content: center;
  }
}
</style>