<template>
  <custom-dialog
    :show="show"
    :title="$t('label.center.editInfo')"
    width="500"
    @toggleShow="handleClose"
  >
    <div class="flex form" slot="main">
      <el-form ref="form" :model="form" label-width="158px" :rules="rules">
        <el-form-item
          :label="$t('label.center.username') + '：'"
          prop="username"
        >
          <el-input
            size="small"
            v-model="form.username"
            :placeholder="$t('label.center.username')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.center.belong') + '：'" prop="belong">
          <el-cascader
            size="small"
            v-model="form.belong"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item :label="$t('label.center.phone') + '：'" prop="phone">
          <el-input
            size="small"
            v-model="form.phone"
            :placeholder="$t('label.center.phone')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="submit">
        {{ $t("dialog.userList.dispose") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import { getLocation, editUserInfo } from "@/api/index.js";
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "ReceiverAdminR51EditInfo",
  components: {
    CustomDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    // 校验手机号
    var validatePhone = (rule, value, callback) => {
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        callback(new Error(this.$t("label.center.number")));
      } else if (!re.test(value)) {
        callback(new Error(this.$t("label.center.phoneCorrect")));
      } else {
        callback();
      }
    };
    // 用户名称
    var validateName = (rule, value, callback) => {
      var pattern = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error(this.$t("label.center.name")));
      } else if (pattern.test(value)) {
        callback(new Error(this.$t("label.center.numAndLetterAndC")));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        belong: [],
        phone: "",
      },
      value: [],
      options: [
        {
          value: "上海",
          label: "中国",
          children: [
            {
              value: "上海市",
              label: "上海市",
              children: [
                {
                  value: "青浦区",
                  label: "上海市",
                },
              ],
            },
          ],
        },
      ],
      rules: {
        username: [
          { required: true, validator: validateName, trigger: "blur" },
        ],
        belong: [
          { required: true, message: "请选择归属地", trigger: "change" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
    };
  },

  mounted() {
    this.getLocationList();
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue) {
          console.log(newValue, "newwwwwwww")
          this.form.username = this.data.username;
          this.form.belong = [
            this.data.province,
            this.data.city,
            this.data.district,
          ];
          this.form.phone = this.data.phone;
        }
      },
      deep: true,
    },
  },
  methods: {
    filterData(data) {
      // 如果最后一级children为空，则删除该字段
      if (data.children.length === 0) {
        // delete data.children;
        data = {
          label: data.label,
          value: data.value
        }
      } else {
        // 继续递归过滤子级数据
        data.children = data.children.map((child) => this.filterData(child));
      }
      return data;
    },
    getLocationList() {
      getLocation().then((res) => {
        if (res.code == 200) {
          this.options = res.data.region.map((item) => {
              return this.filterData(item)
          });
        }
      });
    },
    handleClose(done) {
      this.$emit("toggleShow", false);
      // this.$refs['form'].clearValidate()
      this.$refs["form"].resetFields();
    },
    handleChange(value) {
      console.log(this.form, "fff");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 校验通过
          let param = {
            // id: JSON.parse(localStorage.getItem("userInfo")).id,
            // type: JSON.parse(localStorage.getItem("userInfo")).type,
            username: this.form.username,
            province: this.form.belong[0],
            city: this.form.belong[1],
            district: this.form.belong[2],
            phone: this.form.phone,
          };
          // this.handleClose()
          editUserInfo(param).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: this.$t("label.center.success"),
              });
              this.handleClose()
              this.$emit('refush')
            } 
            // else {
            //     this.$message({
            //     type: "error",
            //     message: "个人信息修改失败！",
            //   });
            // }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
::v-deep {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
}
</style>