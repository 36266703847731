<template>
  <div class="satelliteConfig" v-loading="isLoading">
    <div class="title">{{ $t("label.satelliteConfig.title") }}</div>
    <div class="satelliteConfig-form">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="left"
        :rules="rules"
      >
        <el-form-item
          prop="cutoff_angle"
          :label="$t('label.satelliteConfig.cutoff_angle') + '：'"
        >
          <el-input
            style="width: 100%"
            v-model="form.cutoff_angle"
            :placeholder="$t('label.satelliteConfig.cutoff_angle')"
          ></el-input>
        </el-form-item>
        <div
          class="flex satellites"
          v-for="satellite in satellites"
          :key="satellite.label"
        >
          <div class="satellites-label" style="width: 120px">
            {{ satellite.label }}
          </div>
          <div class="satellites-radio">
            <el-radio-group v-model="form[satellite.key]">
              <el-radio :label="true">{{
                $t("label.satelliteConfig.enable")
              }}</el-radio>
              <el-radio :label="false">{{
                $t("label.satelliteConfig.forbidden")
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <el-form-item
          :label="$t('label.satelliteConfig.difference_age') + '：'"
          prop="difference_age"
        >
          <el-input
            style="width: 100%"
            v-model="form.difference_age"
            :placeholder="$t('label.satelliteConfig.difference_age')"
          ></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="flex model-btns">
      <el-button type="primary" class="btn save" size="small" @click="save">
        {{ $t("label.model.save") }}
      </el-button>
      <el-button type="success" class="btn cancel" size="small" @click="cancel">
        {{ $t("label.model.cancel") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { receiverProConfig, satelliteInfo } from "@/api/index";
export default {
  data() {
    var cutoffAngle = (rule, value, callback) => {
      // console.log(value)
      if (Number(value) >= 0 && Number(value) <= 90) {
        callback();
      } else {
        return callback(new Error(this.$t("label.satelliteConfig.90")));
      }
    };
    var differenceAge = (rule, value, callback) => {
      // console.log(value)
      if (Number(value) >= 1 && Number(value) <= 600) {
        callback();
      } else {
        return callback(new Error(this.$t("label.satelliteConfig.600")));
      }
    };
    return {
      formItem: [
        {
          label: "label.model.lonlat_format",
          key: "lonlat_format",
          inputType: "select",
          selectKey: "option.model.lonlat_format_options",
        },
        {
          label: "label.model.longitude",
          key: "longitude",
          inputType: "input",
        },
        {
          label: "label.model.latitude",
          key: "latitude",
          inputType: "input",
        },
        {
          label: "label.model.elevation",
          key: "elevation",
          inputType: "input",
        },
      ],
      satellites: [
        {
          label: "BEIDOU",
          value: null,
          key: "beidou_state",
        },
        {
          label: "GLONASS",
          value: null,
          key: "glonass_state",
        },
        {
          label: "GPS",
          value: null,
          key: "gps_state",
        },
        {
          label: "GALILEO",
          value: null,
          key: "galileo_state",
        },
        {
          label: "QZSS",
          value: null,
          key: "qzss_state",
        },
        {
          label: "SBAS",
          value: null,
          key: "sbas_state",
        },
      ],
      form: {
        cutoff_angle: "10", // 卫星截至角
        beidou_state: null,
        glonass_state: null,
        gps_state: null,
        galileo_state: null,
        qzss_state: null,
        sbas_state: null,
        // difference_age: 20, // 差分龄期
      },
      rules: {
        cutoff_angle: [{ validator: cutoffAngle, trigger: "blur" }],
        // difference_age: [{ validator: differenceAge, trigger: "blur" }],
      },
      isLoading: false,
    };
  },
  activated() {
    this.initData();
    this.$refs["form"].clearValidate();
  },
  methods: {
    initData() {
      this.isLoading = true;
      let param = {
        // sn: JSON.parse(localStorage.getItem('receiverInfo')).sn
        sn: JSON.parse(sessionStorage.getItem("currentSn")),
      };
      satelliteInfo(param).then((res) => {
        if (res.code == 200) {
          this.isLoading = false;
          console.log(res, this.form, "formmmmmmm");
          const {
            cutoff_angle,
            beidou_state,
            glonass_state,
            gps_state,
            galileo_state,
            qzss_state,
            sbas_state,
          } = res.data;
          this.form = {
            cutoff_angle: cutoff_angle, // 卫星截止角
            beidou_state: beidou_state == 1 ? true : false,
            glonass_state: glonass_state == 1 ? true : false,
            gps_state: gps_state == 1 ? true : false,
            galileo_state: galileo_state == 1 ? true : false,
            qzss_state: qzss_state == 1 ? true : false,
            sbas_state: sbas_state == 1 ? true : false,
          };
        }
      });
    },
    // 保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let param = {
            sn: sessionStorage.getItem("currentSn"),
            cutoff_angle: Number(this.form.cutoff_angle), // 卫星截止角
            beidou_state: this.form.beidou_state ? 1 : 0,
            glonass_state: this.form.glonass_state ? 1 : 0,
            gps_state: this.form.gps_state ? 1 : 0,
            galileo_state: this.form.galileo_state ? 1 : 0,
            qzss_state: this.form.qzss_state ? 1 : 0,
            sbas_state: this.form.sbas_state ? 1 : 0,
            // cfi: Number(this.form.difference_age), // 差分龄期
          };
          // console.log(param, "parammmmmmmmm");
          receiverProConfig(param).then((res) => {
            if (res.code === 200) {
              this.$message({
                // message: '保存修改成功！',
                message: this.$t("label.satelliteConfig.save_success_info"),
                type: "success",
                duration: 2000,
              });
              this.initData();
            } else {
              // this.$message.error('网络错误，请重新保存！');
              this.$message({
                message: res.msg,
                type: "error",
                duration: 2000,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.initData();
    },
  },
};
</script>

<style lang="scss" scoped>
.satelliteConfig {
  height: 100%;
  padding: 16px;
  .title {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 0;
  }
  &-form {
    max-width: 500px;
  }
  .satellites {
    line-height: 40px;
    &-label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #606266;
      line-height: 22px;
    }
    &::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: $main_color;
      background: $main_color;
    }
    &::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: $main_color;
    }
  }
  .btn {
    width: 180px;
    height: 32px;
    margin-top: 20px;
    max-width: 48%;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
::v-deep {
  .el-loading-spinner .path {
    stroke: #659b5d;
  }
}
</style>