<template>
  <div class="mapContainer">
    <div class="map" id="map"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language"; // 设置中文语言包
// import Measure from "./measure";
import "mapbox-gl/src/css/mapbox-gl.css";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

export default {
  name: "Map",

  data() {
    return {
      map: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  watch: {
    "$store.getters.sideNavOpen": {
      handler(newValue, old) {
        setTimeout(()=>{
            if(this.map){
                this.map.resize()
            }
        }, 500)
      },
    },
  },
  methods: {
    initMap() {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiaHllb25namlua2ltIiwiYSI6ImNpZXh4dXp5eDA2YjFzaGtyOGR2dnBza2oifQ.a5K673tSr0cOcYoX1rpPhg";
      this.map = new mapboxgl.Map({
        container: "map",
        style: {
          version: 8,
          //字体，不写，聚合内字会消失
          glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
          sources: {
            "raster-tiles": {
              type: "raster",
              tiles: [
              // "http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=af746b368962fc2803eb43a9aa9b42a1"
                "https://t0.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
                // "https://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
              ],
              tileSize: 256,
            },
            "raster-tiles-road": {
              type: "raster",
              tiles: [
                "http://t0.tianditu.com/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
                // "https://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
                // "http://thematic.geoq.cn/arcgis/rest/services/StreetThematicMaps/PurplishBlue_Reference/MapServer/tile/{z}/{y}/{x}"
              ],
              tileSize: 256,
            },
            "raster-tiles-dm": {
              type: "raster",
              tiles: [
                "https://t5.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
              ],
              tileSize: 256,
            },
            "raster-tiles-bj": {
              type: "raster",
              tiles: [
                "https://t5.tianditu.gov.cn/DataServer?T=ibo_w&x={x}&y={y}&l={z}&tk=af746b368962fc2803eb43a9aa9b42a1",
              ],
              tileSize: 256,
            },
          },
          layers: [
            {
              id: "simple-tiles",
              type: "raster",
              source: "raster-tiles",
              minzoom: 0,
              maxzoom: 24,
            },
            {
              id: "simple-tiles-load",
              type: "raster",
              source: "raster-tiles-road",
              minzoom: 0,
              maxzoom: 24,
            },
            {
              id: "simple-tiles-bj",
              type: "raster",
              source: "raster-tiles-bj",
              minzoom: 0,
              maxzoom: 18,
            },
            {
              id: "simple-tiles-dm",
              type: "raster",
              source: "raster-tiles-dm",
              minzoom: 0,
              maxzoom: 18,
            },
          ],
        },
        center: [121.32417, 31.16974],
        zoom: 6,
        maxZoom: 24, // 最大缩放
        minZoom: 0, // 最小缩放
        pitch: 0, //地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
        bearing: 0, //地图的初始方向，值是北的逆时针度数，默认是0，即是正北
        antialias: true, //抗锯齿，通过false关闭提升性能
        // ...this.initOption,
      });
      this.map.on("load", () => {
        this.map.addControl(
          new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: "metric",
          }),
          "bottom-right"
        );
        // this.initMeasureTool(); // 初始化测量工具
        // this.map.addControl(new mapboxgl.NavigationControl(), "bottom-left"); // 地图控件 先隐藏掉
        this.map.doubleClickZoom.disable(); // 禁止鼠标双击缩放
        this.addMethods()
      });
      this.map.on("style.load", () => {
        // this.$emit("styleLoad");
      });
      //   this.map.addControl(new MapboxLanguage({ defaultLanguage: "zh-Hans" }));
    },
    addMethods() {
      // this.map.tp = new this.$trackPlay().init(false, 0.2);
      // this.map.tp = new TrackPlay(false, 0.2);
      // this.map._loadImage = (image) => {
      //   return new Promise((resolve, reject) => {
      //     this.map.loadImage(image, (error, image) => {
      //       if (error) {
      //         resolve({
      //           success: false,
      //           error,
      //         });
      //       } else {
      //         resolve({
      //           success: true,
      //           data: image,
      //         });
      //       }
      //     });
      //   });
      // };

      // // 组件转html
      // this.map._vmToPopup = this.handlerVMToPopup;
      // this.map.updateSize = () => {
      //   this.map.resize();
      // };

      // this.map.getUUID = this.getUUID;
      this.$emit("load", this.map);
    },
  },
};
</script>

<style lang="scss" scoped>
.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
  .map {
    height: 100%;
  }
}
</style>