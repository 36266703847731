<template>
	<el-dialog
		class="custom-dialog manageDialog"
		:class="{ notPaddingTop: notPaddingTop }"
		:visible.sync="dialogVisible"
		:width="`${width}px`"
		:show-close="false"
		:modal-append-to-body="appendToBody"
		:before-close="handleClose"
		:fullscreen="deviceInfo.type == 'MOBIE'"
	>
		<template #title>
			<slot name="title">
				<div class="dialogTitle" :style="`text-align:${titleAlign};`">
					<h4>
						{{ title }}
					</h4>
					<i class="closeDia el-icon-close" @click="handleClose"></i>
				</div>
			</slot>
		</template>
		<template>
			<slot name="main">
				<div class="dialogMain"></div>
			</slot>
		</template>
		<template slot="footer" class="dialog-footer">
			<slot name="footer">
				<div class="dialogFooter"></div>
			</slot>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		name: "ManageDialog",
		props: {
			show: {
				type: Boolean,
				default: true,
			},
			title: {
				type: String,
				default: "标题",
			},
			titleAlign: {
				type: String,
				default: "center",
			},
			width: {
				type: [String,Number],
				default: "600",
			},
			appendToBody: {
				type: Boolean,
				default: false,
			},
			notPaddingTop: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				dialogVisible: this.show,
			};
		},
		computed:{
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
		},
		watch: {
			show(val) {
				this.dialogVisible = val;
			},
		},
		methods: {
			handleClose(done) {
				this.$emit("toggleShow", false);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.manageDialog {
		&::v-deep .el-dialog {
			border-radius: 8px;
			overflow: hidden;
		}
		.dialogTitle {
			background-color: #f4f7fa;
			line-height: 50px;
			height: 50px;
			position: relative;
			padding: 0 20px;
			border-radius: 2px 2px 0 0;
			overflow: hidden;
			& h4 {
				display: inline-block;
				max-width: 80%;
			}
			.closeDia {
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				font-size: 22px;
				font-weight: bold;
				cursor: pointer;
			}
		}
		.dialogMain {
			background-color: #fff;
		}
		.dialogFooter {
			min-height: 1px;
			background-color: #fff;
			border-radius: 0 0 2px 2px;
		}
		
		&::v-deep .el-dialog__footer {
			background-color: #fff;
			transform: translateY(-1px);
		}
		&::v-deep .el-dialog__body {
			background-color: #fff;
			padding: 30px 20px !important;
		}
	}
	.notPaddingTop {
		&::v-deep .el-dialog__body {
			padding-top: 0 !important;
		}
	}
</style>
