<template>
	<div class="panel" :class="[classType]">
		<div class="panel-title">
			{{ title }}
		</div>
		<div class="flex panel-list">
			<div class="flex panel-list-item" v-for="item in data" :key="item.label">
				<span class="panel-list-item-label">{{ item.label }}</span>
				<span class="panel-list-item-value">{{ item.value || "N/A" }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Panel",
		props: {
			classType: {
				type: String,
				default: "",
			},
			data: {
				type: Array,
				default: () => {
					return [];
				},
			},
			title: {
				type: String,
				default: "",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.panel {
		&-title {
			line-height: 43px;
		}
	}
	.PC {
		padding: 0 10px;
		max-width: 48%;
		min-width: 48%;
		border-radius: 4px;
		border: 1px solid $main_color;
		margin-bottom: 20px;
		& + .PC {
			margin-left: 2%;
		}
		&:nth-child(2n + 1) {
			margin-left: 0;
		}
		.panel-title {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #539d56;
			line-height: 36px;
		}
		.panel-list {
			flex-wrap: wrap;
			align-items: flex-start;
			border-top: 1px solid $main_color;
			&-item {
				min-width: 50%;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 32px;
				// &:nth-child(2n) {
				// 	text-align: right;
				// }
			}
		}
	}
	.MOBIE {
		width: 100%;
		.panel-title {
			font-weight: 600;
		}
		.panel-list {
			padding: 0 10px;
			flex-wrap: wrap;
			border-radius: 4px;
			border: 1px solid $main_color;
			&-item {
				width: 100%;
				line-height: 40px;
				justify-content: space-between;
				& + .panel-list-item {
					border-top: 1px solid #e0e0e0;
				}
				&-label {
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #333333;
				}
			}
		}
	}
</style>