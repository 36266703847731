<template>
  <div class="container" v-loading="isLoading">
    <div class="title">
      {{ $t("public.center") }}
    </div>
    <div class="info">
      <div class="infoItem flex mgt20">
        <div class="minW">{{ $t("label.center.username") }}：</div>
        <div class="mgl20">{{ username }}</div>
      </div>
      <div class="infoItem flex mgt20">
        <div class="minW">{{ $t("label.center.belong") }}：</div>
        <div class="mgl20">{{ belong }}</div>
      </div>
      <div class="infoItem flex mgt20">
        <div class="minW">{{ $t("label.center.phone") }}：</div>
        <div class="mgl20">{{ phone }}</div>
      </div>
    </div>
    <div class="btn mgt20">
      <el-button size="small" type="primary" @click="editInfo">{{
        $t("label.center.editInfo")
      }}</el-button>
      <el-button size="small" type="primary" @click="editPwd">{{
        $t("label.center.editPwd")
      }}</el-button>
    </div>
    <!--  -->
    <editInfo
      :show="infoShow"
      @toggleShow="closeDialog"
      :data="userInfo"
      @refush="getUserInfoData"
    ></editInfo>
    <editPwd :show="pwdShow" @toggleShow="closeDialog"></editPwd>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/index.js";
import editInfo from "./component/editInfo.vue";
import editPwd from "./component/editPwd.vue";
export default {
  name: "center",
  components: {
    editInfo,
    editPwd,
  },
  data() {
    return {
      isLoading: false,
      infoShow: false,
      pwdShow: false,
      username: "",
      belong: "",
      phone: "",
      userInfo: null,
    };
  },

  mounted() {
    this.getUserInfoData();
  },

  methods: {
    getUserInfoData() {
      let param = {
        id: JSON.parse(localStorage.getItem("userInfo")).id
      }
      this.isLoading = true;
      getUserInfo(param).then((res) => {
        if (res.code == 200) {
          this.isLoading = false;
          this.userInfo = res.data;
          this.username = this.userInfo.username;
          this.belong =
            this.userInfo.province +
            "/" +
            this.userInfo.city +
            "/" +
            this.userInfo.district;
          this.phone = this.userInfo.phone;
        }
      });
    },
    editInfo() {
      this.infoShow = true;
    },
    editPwd() {
      this.pwdShow = true;
    },
    closeDialog() {
      this.infoShow = false;
      this.pwdShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-loading-spinner .path {
    stroke: #659b5d;
  }
}
.container {
  padding: 20px;
  .minW {
    min-width: 80px;
  }
}
.mgt20 {
  margin-top: 20px;
}
.el-button {
  border: none;
}
.el-button--primary {
  background-color: #659b5d;
}
</style>