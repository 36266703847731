<template>
	<div>
		<custom-dialog
			:show="show"
			:title="$t('public.equip_manage')"
			@toggleShow="handleClose"
			:notPaddingTop="true"
		>
			<div class="content" slot="main">
				<div class="content-detail">
					<span> {{ $t("dialog.userList.user_name") }}：{{ form.name }}</span>
					<span class="mar-lef">
						{{ $t("dialog.userList.user_type") }}：{{ form.accountType }}</span
					>
					<span class="mar-lef"> {{ $t("dialog.userList.dealer") }}：{{ form.dealer }}</span>
				</div>
				<div class="flex content-query">
					<div class="content-query-input">
						<el-input
							:placeholder="
								$t('public.please_input') + $t('dialog.userList.query_sn')
							"
							size="small"
							v-model="queryData.keyWords"
							suffix-icon="el-icon-search"
						>
						</el-input>
					</div>
					<div class="flex content-query-btns">
						<el-button
							type="primary"
							class="btn"
							size="mini"
							style="margin-right: 8px"
							@click="toggleShowAddSn"
						>
							{{ $t("dialog.userList.add_equip") }}
						</el-button>
						<el-upload
							class="upload-demo"
							action="https://jsonplaceholder.typicode.com/posts/"
							:limit="1"
							:file-list="fileList"
							:show-file-list="false"
						>
							<el-button type="success" class="btn" size="mini">
								{{ $t("dialog.userList.batch_add_equip") }}
							</el-button>
						</el-upload>
					</div>
				</div>
				<div class="content-table">
					<el-table :data="tableData" stripe max-height="250">
						<el-table-column
							v-for="column in columns"
							:key="column.label"
							:prop="column.label"
							:label="$t(`dialog.userList.${column.label}`)"
							align="center"
							:min-width="column == 'operate' ? 150 : 'auto'"
						>
							<template slot-scope="{ row }">
								<template v-if="column == 'dealer'">
									<span v-if="row.dealer">
										{{ row[column] }}
									</span>
									<span v-else> / </span>
								</template>
								<template v-else-if="column.label == 'operate'">
									<el-button type="danger" size="small">{{
										$t("dialog.userList.delete")
									}}</el-button>
								</template>
								<template v-else>
									{{ row[column.value] }}
								</template>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination
							background
							layout="prev, pager, next"
							:total="total"
							@current-change="currentChange">
						</el-pagination>
					</div>
				</div>
			</div>
			<div slot="footer">
				<div class="flex btns">
					<el-button type="primary" class="btn save" size="small">
						{{ $t("dialog.userList.save") }}
					</el-button>
					<el-button type="success" class="btn cancel" size="small">
						{{ $t("dialog.userList.close") }}
					</el-button>
				</div>
				
			</div>
			
		</custom-dialog>
		<EditSn :show="showAddSn" @toggleShow="toggleShowAddSn"></EditSn>
	</div>
</template>

<script>
	import CustomDialog from "../../../components/public/customDialog";
	import EditSn from "./editSN.vue";
	export default {
		name: "EquipList",
		components: {
			CustomDialog,
			EditSn,
		},

		props: {
			show: {
				type: Boolean,
				default: false,
			},
			rowInfo: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				columns: [
					{
						label: "serial_number",
						value: "serial_number",
					},
					{
						label: "query_sn",
						value: "sn",
					},
					{
						label: "operate",
						value: "",
					},
				],
				queryData: {
					keyWords: "",
				},
				tableData: [
					{
						id: 1,
						serial_number: "1",
						sn: "12345678910",
					},
					{
						id: 2,
						serial_number: "2",
						sn: "12345678920",
					},
					{
						id: 3,
						serial_number: "3",
						sn: "12345678930",
					},
				],
				fileList: [],
				form: {
					name: "",
					contact: "",
					account: "",
					accountType: "",
					dealer: "",
				},
				showAddSn: false,
				total: 50
			};
		},
		computed: {
			title() {
				if (this.rowInfo && this.rowInfo.id) {
					return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
				} else {
					return this.$t("dialog.userList.add_title");
				}
			},
			currentFormItem() {
				if (this.rowInfo && this.rowInfo.id) {
					return this.formItem.filter(
						(f) => f.key == "accountType" || f.key == "contact"
					);
				} else {
					return this.formItem;
				}
			},
		},
		watch: {
			rowInfo: {
				handler(val) {
					if (val) {
						this.form = this.$tool.clone(val);
					} else {
						this.form = {
							name: "",
							contact: "",
							account: "",
							accountType: "",
							dealer: "",
						};
					}
				},
				deep: true,
			},
		},
		methods: {
			handleClose(done) {
				this.$emit("toggleShow", false);
			},
			toggleShowAddSn() {
				this.showAddSn = !this.showAddSn;
			},
			// 页数
			currentChange(e){
				console.log("页数",e);
			}
		},
	};
</script>

<style lang="scss" scoped>
	.btns {
		justify-content: center;
		.btn {
			width: 180px;
			height: 32px;
		}
		.save {
			background-color: $main_color;
			border-color: $main_color;
		}
		.cancel {
			border-color: $main_color;
			background-color: #fff;
			color: $main_color;
			margin-left: 30px;
		}
	}
	.content {
		margin-top: 0 !important;
		&-query {
			margin: 16px 0;
			justify-content: space-between;
			&-input {
				width: 180px;
			}
			&-btns {
			}
		}
		&-table {
			border-radius: 2px 2px 0px 0px;
			overflow: hidden;
			&::v-deep th.el-table__cell {
				background-color: $table_header;
				color: #fff;
			}
			&::v-deep td.el-table__cell {
				background: transparent !important;
			}
			&::v-deep .el-table__row--striped {
				background-color: #d2e9ce;
			}
		}
	}
	::v-deep{
			.el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #3b9167;
				color: #FFF;
			}
			.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
				background-color: #ffffff;
				border: 1px solid #e8e8e8;
			}
			.el-pagination.is-background .el-pager li:hover {
    			color: #3b9167;
    		}	
			// .el-pagination.is-background .el-pager li:not(.disabled):hover {
			// 	color: #3b9167;
			// }
		}
		.pagination{
			margin-top: 10px;
			display: flex;
			justify-content: center;
		}
		.mar-lef{
			margin-left: 50px;
		}
</style>