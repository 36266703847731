<template>
  <div class="position" ref="position">
    <div class="bg" :style="{
      '--width': width
    }">
      <!-- <img src="../../../assets/images/public/satellite_bg.png" alt=""> -->
      <div class="draw" ref="draw">
        <div class="draw-content">
          <div class="flex item" :class="[item.type]" v-for="item in showList" :data-value="item.id"
            :key="item.satellite_type" :style="{
              bottom: item.pos.y + 'px',
              left: item.pos.x + 'px',
            }"></div>
        </div>
      </div>
    </div>
    <div class="flex checkBox">
      <el-checkbox-group v-model="checkList" text-color="#333333">
        <el-checkbox v-for="item in satellites" :style="{
          '--color': dict[item.value]
        }" :key="item.value" :label="item.value"></el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return {
        }
      }
    },
    satellites: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      base: 0.204,
      baseRadius: 0,// 图形半径
      currentList: [],
      checkList: this.satellites.map(f => f.value),
      dict: {
        GPS: "#00AE2C",
        GLONASS: "#C86404",
        BDS: "#BB0202",
        GAL: "#446AD5",
        QZSS: "#10D0CE",
        SBAS: "#8F01A4",
      },
    }
  },
  watch: {
    '$store.getters.deviceInfo': {
      handler(val) {
        this.$nextTick(() => {
          this.reSize();
        });
      },
      deep: true,
      immediate: true,
    },
    active: {
      handler(val) {
        if (val == 'position') {
          this.$nextTick(() => {
            this.currentList = this.handleXY(this.tableData);
            this.reSize();
          })
        }
      },
      deep: true,
      // immediate: true
    }
  },
  computed: {
    width() {
      return this.baseRadius * 2 + 'px'
    },
    showList() {
      return this.currentList.filter(f => {
        return this.checkList.includes(f.type);
      })
    }
  },
  methods: {
    reSize(e) {
      this.baseRadius = this.$refs.position.clientWidth * (1 - this.base) / 2;
      this.$nextTick(() => {
        this.currentList = this.handleXY(this.currentList);
      })
    },
    handleXY(val) {
      return this.$tool.clone(val).map(f => {
        f.pos = this.$tool.getXY({
          elevation: Number(f.hight_angle), azimuth: Number(f.position_angle), Radius: this.baseRadius
        })
        f.pos.x = f.pos.x - 1
        f.pos.y = f.pos.y - 1
        return f
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.position {
  max-width: 500px;
  width: 100%;

  .bg {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    background-image: url("../../../assets/images/public/satellite_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;


    .draw {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-49%, -50.5%);
      z-index: 10;
      // border: 1px solid #ff0000;
      width: var(--width);
      height: var(--width);
      border-radius: 50%;

      &-content {
        height: 100%;
        border-radius: 50%;
        position: relative;

        .item {
          position: absolute;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          // background-color: $nav_bg;
          // transform: translate(-50%, -50%);
          cursor: pointer;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 14px;

          &::before {
            content: attr(data-value);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            justify-content: center;
          }
        }

        .GLONASS::before {
          background-image: url("../../../assets/images/public/GLN.png");
        }

        .BDS::before {
          background-image: url("../../../assets/images/public/BDS.png");
        }

        .GAL::before {
          background-image: url("../../../assets/images/public/GAL.png");
        }

        .QZSS::before {
          background-image: url("../../../assets/images/public/QZSS.png");
        }

        .SBAS::before {
          background-image: url("../../../assets/images/public/SBAS.png");
        }

        .GPS::before {
          background-image: url("../../../assets/images/public/GPS.png");
        }
      }
    }

    &>img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .checkBox {
    white-space: nowrap;
    justify-content: center;

    &::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: var(--color);
      border-color: var(--color);
    }

    &::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #333;
    }
  }
}
</style>