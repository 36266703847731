<template>
  <div class="userList">
    <div class="pageTitle">{{ $t("label.userList.page_title") }}</div>
    <div class="flex query">
      <el-input
        :placeholder="$t('public.please_input')"
        v-model="queryData.keyWords"
        style="max-width: 300px"
        size="small"
        @keyup.enter.native="toSearch"
      >
        <template slot="prepend">
          <el-select
            v-model="queryData.type"
            style="width: 80px"
            @change="selectChange"
            size="small"
          >
            <el-option
              v-for="item in $t('option.userList.query_options')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template slot="append">
          <el-button class="el-icon-search" @click="toSearch"></el-button>
        </template>
      </el-input>
      <el-button
        type="success"
        style="height: 32px; margin-left: 20px"
        icon="el-icon-user"
        size="mini"
        @click="openDetail()"
        >{{ $t("label.userList.add") }}</el-button
      >
    </div>
    <div class="table">
      <el-table :data="tableData" stripe :max-height="tableHeight" ref="form">
        <el-table-column
          v-for="column in columns"
          :key="column"
          :prop="column"
          :label="$t(`label.userList.${column}`)"
          align="center"
          :min-width="column == 'operate' ? 300 : '120'"
        >
          <template slot-scope="{ row }">
            <template v-if="column == 'dealer'">
              <span v-if="row.dealer">
                {{ row[column] }}
              </span>
              <span v-else> / </span>
            </template>
            <template v-else-if="column == 'belong'">
              <span v-if="row.belong">
                {{ row[column] }}
              </span>
              <span v-else> / </span>
            </template>
            <template v-else-if="column == 'operate'">
              <el-button type="success" size="small" @click="openDetail(row)">{{
                $t("label.userList.edit")
              }}</el-button>
              <el-button type="danger" size="small" @click="openDelete(row)">{{
                $t("label.userList.delete")
              }}</el-button>
              <el-button
                type="success"
                size="small"
                @click="openResetPwd(row)"
                >{{ $t("label.userList.resetPwd") }}</el-button
              >
              <!-- <el-button type="danger" size="small" @click="openEquip(row)">{{
								$t("label.userList.equip")
							}}</el-button> -->
            </template>
            <template v-else>
              {{ row[column] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        :total="total"
        popper-class="mySelectType"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <edit-user
      :show="showDetail"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
      @refresh="refreshList"
    ></edit-user>
    <equip-list
      :show="showEquips"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
    ></equip-list>
    <reset-pwd
      :userInfo="userInfo"
      :show="showResetPwd"
      :rowInfo="rowInfo"
      @toggleShow="closeDia"
    >
    </reset-pwd>
    <ConfirmDialog
      :title="message.title"
      :message="message.content"
      :show="showDelete"
      @toggleShow="closeDia"
      @submit="closeDia"
      @delete="toDelete"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { userList, deleteUser } from "@/api/index";
import editUser from "./component/editUser.vue";
import EquipList from "./component/equipList.vue";
import ResetPwd from "./component/resetPwd.vue";
import ConfirmDialog from "../../components/public/confirmDialog";
export default {
  components: { editUser, EquipList, ResetPwd, ConfirmDialog },
  data() {
    return {
      queryData: {
        type: "名称",
        value: "name",
        keyWords: "",
      },
      columns: [
        "name",
        "contact",
        "account",
        "belong",
        "accountType",
        // "type",
        "dealer",
        "operate",
      ],
      tableData: [],
      showDetail: false,
      showEquips: false,
      showResetPwd: false,
      showDelete: false,
      rowInfo: null,
      total: 0,
      page: 1,
      pageSize: 10,
      userName: "",
      account: "",
      searchType: "name", // 搜索类型
      message: {
        title: "",
        content: "",
      },
      userInfo: {},
      lineData: {},
      tableHeight: 500,
      timer: null,
    };
  },
  activated() {
    this.userName = "";
    this.account = "";
    this.searchType = "name";
    this.queryData.type = this.$t("label.userList.name");
    this.queryData.keyWords = "";
    this.getUserInfoList();
    this.setTimer();
  },
  mounted() {
    this.$nextTick(() => {
      this.getUserInfoList();
      // 动态计算table高度
      let height = window.innerHeight - this.$refs.form.$el.offsetHeight - 130;
      this.tableHeight = height;
      // console.log(this.tableHeight);
    });
  },
  methods: {
    getUserInfoList() {
      let param = {
        page_num: this.page,
        page_size: this.pageSize,
        username: this.userName,
        account: this.account,
      };
      userList(param).then((res) => {
        // let table = []
        if (res.code === 200) {
          this.tableData = res.data.rows.map((item, index) => {
            return {
              id: item.id,
              name: item.username,
              contact: item.phone,
              account: item.account,
              accountType: item.type === 2 ? "经销商" : "个体用户",
              // accountType: item.type,
              // type: item.type === 2 ? "经销商" : "个体用户",
              dealer: item.agent_info.username,
              pid: item.agent_info.id,
              belong: item.province
                ? item.province + item.city + item.district
                : "",
              province: item.province,
              district: item.district,
              city: item.city,
              class: item.class
            };
          });
          this.total = res.data.total;
        }
        // console.log(res,"rrr")
      });
    },
    openDetail(val) {
      this.showDetail = true;
      // console.log(val, "lll");
      this.rowInfo = val;
    },
    openEquip(val) {
      this.showEquips = true;
      this.rowInfo = val;
    },
    // 重置密码
    openResetPwd(val) {
      this.userInfo = val;
      this.showResetPwd = true;
      this.rowInfo = val;
    },
    // 删除
    openDelete(val) {
      // console.log(val);
      this.message.title = this.$t("public.warning");
      this.message.content = this.$t("message.userList.delete");
      this.showDelete = true;
      this.lineData = val;
    },
    closeDia(val) {
      // console.log(val)
      this.showDetail = false;
      this.showEquips = false;
      this.showResetPwd = false;
      this.showDelete = false;
      this.timer = setTimeout(() => {
        this.rowInfo = null;
      }, 250);
    },
    // 清空定时器
    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      } else {
        return;
      }
    },
    selectChange(e) {
      this.searchType = e;
      // console.log(e);
    },
    // 搜索
    toSearch() {
      this.page = 1;
      // this.pageSize = 10
      if (this.searchType === "name") {
        this.userName = this.queryData.keyWords;
        this.account = "";
      } else {
        this.userName = "";
        this.account = this.queryData.keyWords;
      }
      this.getUserInfoList();
    },
    // 分页
    sizeChange(e) {
      // console.log("分页", e);
      this.pageSize = e;
      this.getUserInfoList();
    },
    // 页数
    currentChange(e) {
      this.page = e;
      // console.log("页数", e);
      this.getUserInfoList();
    },
    toDelete() {
      // console.log(this.lineData)
      let param = {
        id: this.lineData.id,
      };
      // console.log(param);
      // return
      deleteUser(param).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            // message: "已成功删除此用户！",
            message: this.$t("message.userList.delete_success_info"),
            duration: 2000,
          });
          this.getUserInfoList();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 2000,
          });
        }
      });
    },
    refreshList() {
      this.getUserInfoList();
    },
  },
};
</script>

<style lang="scss" scoped>
.userList {
  // height: calc(100vh);
  padding: 0 20px;

  .query {
    // max-width: 400px;
    justify-content: space-between;
  }

  .table {
    margin-top: 10px;
    border-radius: 2px 2px 0px 0px;
    // height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::v-deep th.el-table__cell {
      background-color: $table_header;
      color: #fff;
    }

    &::v-deep td.el-table__cell {
      background: transparent !important;
    }

    &::v-deep .el-table__row--striped {
      background-color: #d2e9ce;
    }
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #3b9167;
      color: #fff;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
    }

    .el-pagination.is-background .el-pager li:hover {
      color: #3b9167;
    }

    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #3b9167;
    }

    // .el-select .el-input__inner:focus {
    //   border-color: #3b9167;
    // }

    .el-select-dropdown__item.selected {
      color: #3b9167 !important;
    }

    // .el-pagination.is-background .el-pager li:not(.disabled):hover {
    // 	color: #3b9167;
    // }
  }
  .pagination {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // margin-top: 12px;
    position: fixed;
    bottom: 0px;
    right: 10px;
    width: calc(100% - 230px);
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    z-index: 999;
    padding-bottom: 5px;
  }
}
</style>
<style lang="scss">
.mySelectType {
  .el-select-dropdown__item.selected {
    color: #3b9167 !important;
  }
}
</style>
