var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postSet"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("label.postSet.page_title")))]),_c('div',{staticClass:"postSet-table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"tableData",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":_vm.tableHeight,"stripe":"","empty-text":" "}},[_vm._l((_vm.columns),function(column){return [(column == 'index')?[_c('el-table-column',{key:column,attrs:{"prop":column,"label":_vm.$t(("label.postSet." + column)),"min-width":"60","align":"center"}})]:(column == 'status')?[_c('el-table-column',{key:column,attrs:{"prop":column,"label":_vm.$t(("label.postSet." + column)),"min-width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"normal",style:({
                  background:
                    row.status == 2
                      ? 'green'
                      : row.status == 1
                      ? 'orange'
                      : '#eeeeee',
                })})]}}],null,true)})]:(column == 'operate')?[_c('el-table-column',{key:column,attrs:{"prop":column,"label":_vm.$t(("label.postSet." + column)),"align":"center","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(!(row.index == 1 || row.index == 2))?_c('el-button',{attrs:{"size":"mini","disabled":row.status != 1 && row.status != 2,"type":row.status == 2
                    ? 'danger'
                    : row.status == 1
                    ? 'primary'
                    : ''},on:{"click":function($event){return _vm.startOrStop(row)}}},[_vm._v(_vm._s(row.status == 2 ? _vm.$t("label.postSet.stop") : row.status == 1 ? _vm.$t("label.postSet.start") : _vm.$t("label.postSet.start")))]):_vm._e(),(!(row.index == 1 || row.index == 2))?_c('el-button',{attrs:{"size":"mini","disabled":row.status != 1 && row.status != 2,"type":row.status != 1 && row.status != 2 ? '' : 'primary'},on:{"click":function($event){return _vm.openEditPost(row)}}},[_vm._v(_vm._s(_vm.$t("label.postSet.setting")))]):_vm._e()]}}],null,true)})]:[_c('el-table-column',{key:column,attrs:{"prop":column,"label":_vm.$t(("label.postSet." + column)),"align":"center","min-width":"100"}})]]})],2)],1),_c('edit-post',{attrs:{"show":_vm.showPost,"rowInfo":_vm.rowInfo},on:{"toggleShow":_vm.closeDialog,"refush":_vm.getIOList,"start":_vm.startOrStop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }