<template>
	<custom-dialog
		:show="show"
		:title="$t('public.tip')"
		@toggleShow="handleClose"
		width="400"
	>
		<div class="flex form" slot="main">
			<el-form
				ref="form"
				:model="form"
				label-width="100px"
				label-position="left"
			>
				<el-form-item
					:label="$t(item.label) + '：'"
					v-for="item in currentFormItem"
					:key="item.key"
				>
					<template v-if="item.inputType == 'select'">
						<el-select
							v-model="form[item.key]"
							style="width: 250px"
							size="small"
							:placeholder="$t(item.label)"
						>
							<el-option
								v-for="option in $t(item.selectKey)"
								:key="option.value"
								:label="option.label"
								:value="option.value"
							>
							</el-option>
						</el-select>
					</template>
					<template v-else-if="item.inputType == 'input'">
						<template v-if="item.key == 'elevation'">
							<div class="flex">
								<el-input
									v-model="form[item.key]"
									:placeholder="$t(item.label)"
								></el-input>
								<span style="margin: 0 12px">m</span>
								<el-button type="primary" class="btn save" size="small">
									{{ $t("label.model.getLonlat") }}
								</el-button>
							</div>
						</template>
						<template v-else>
							<el-input
								size="small"
								style="width: 250px"
								v-model="form[item.key]"
								:placeholder="$t(item.label)"
							></el-input>
						</template>
					</template>
				</el-form-item>
			</el-form>
		</div>

		<div class="flex btns" slot="footer">
			<el-button
				type="primary"
				class="btn save"
				@click="handleSubmit"
				size="small"
			>
				{{ $t("dialog.userList.submit") }}
			</el-button>
			<el-button
				type="success"
				class="btn cancel"
				size="small"
				@click="handleClose()"
			>
				{{ $t("dialog.userList.cancel") }}
			</el-button>
		</div>
	</custom-dialog>
</template>

<script>
	import CustomDialog from "../../../components/public/customDialog";
	export default {
		name: "ConfimePwd",
		components: {
			CustomDialog,
		},

		props: {
			show: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				formItem: [
					{
						label: "dialog.updata.pwd_label",
						key: "password",
						inputType: "input",
					},
				],
				form: {
					password: "",
				},
			};
		},
		computed: {
			currentFormItem() {
				if (this.rowInfo && this.rowInfo.id) {
					return this.formItem.filter(
						(f) => f.key == "accountType" || f.key == "contact"
					);
				} else {
					return this.formItem;
				}
			},
		},
		watch: {
			show: {
				handler(val) {
					this.form = {
						sn: "",
					};
				},
				deep: true,
			},
		},
		methods: {
			handleClose(done) {
				this.$emit("toggleShow", false);
			},
			handleSubmit() {
				this.$emit("submit");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.btns {
		justify-content: center;
		.btn {
			width: 180px;
			height: 32px;
		}
		.save {
			background-color: $main_color;
			border-color: $main_color;
		}
		.cancel {
			border-color: $main_color;
			background-color: #fff;
			color: $main_color;
			margin-left: 30px;
		}
	}
	.form {
		justify-content: center;
	}
</style>