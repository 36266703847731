<template>
  <custom-dialog
    :show="show"
    :title="$t('dialog.userList.reset_title')"
    @toggleShow="handleClose"
    width="450"
  >
    <div class="flex form" slot="main">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :rules="rules"
        hide-required-asterisk
        :label-position="
          lang == 'ru-RU' || lang == 'tr-TR' || lang == 'es' ? 'top' : 'left'
        "
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <template v-if="item.inputType == 'select'">
            <el-select
              v-model="form[item.key]"
              style="width: 268px"
              size="small"
              :placeholder="$t(item.label)"
            >
              <el-option
                v-for="option in $t(item.selectKey)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button type="primary" class="btn save" size="small">
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-input
                size="small"
                style="width: 268px"
                :show-password="item.inputModel == 'pwd'"
                :maxlength="item.inputModel == 'pwd'? 15 : 100"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button type="primary" class="btn save" size="small" @click="submit">
        {{ $t("dialog.userList.submit") }}
      </el-button>
      <el-button
        type="success"
        class="btn cancel"
        size="small"
        @click="handleClose()"
      >
        {{ $t("dialog.userList.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import { resetPassword } from "@/api/index";
import CustomDialog from "../../../components/public/customDialog";
export default {
  name: "EditSn",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    // 校验密码
    var validatePass = (rule, value, callback) => {
      var fh = new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      let hz = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error(this.$t("dialog.userList.enterPwd")));
      } else if (hz.test(value) || fh.test(value)) {
        console.log(value);
        callback(new Error(this.$t("dialog.userList.numAndLetter")));
      } else {
        if (this.form.repassword !== "") {
          this.$refs.form.validateField("repassword");
        }
        callback();
      }
    };
    // 校验确认密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("dialog.userList.surePwd")));
      } else if (value !== this.form.password) {
        callback(new Error(this.$t("dialog.userList.notSame")));
      } else {
        callback();
      }
    };
    return {
      formItem: [
        {
          label: "dialog.userList.new_password",
          key: "password",
          inputType: "input",
          inputModel: "pwd",
        },
        {
          label: "dialog.userList.repassword",
          key: "repassword",
          inputType: "input",
          inputModel: "pwd",
        },
      ],
      form: {
        password: "",
        repassword: "",
      },
      rules: {
        password: [
          // { required: true, validator: validatePass, trigger: "blur" },
          {
            required: true,
            min: 6,
            message: this.$t("dialog.userList.pwdLendth6"),
            trigger: "blur",
          },
        ],
        repassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    title() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.$t("dialog.userList.edit_title") + "-" + this.rowInfo.name;
      } else {
        return this.$t("dialog.userList.add_title");
      }
    },
    currentFormItem() {
      if (this.rowInfo && this.rowInfo.id) {
        return this.formItem.filter(
          (f) => f.key == "accountType" || f.key == "contact"
        );
      } else {
        return this.formItem;
      }
    },
    lang() {
      return this.$store.getters.lang;
    },
  },
  watch: {
    show: {
      handler(val) {
        this.form = {
          sn: "",
        };
      },
      deep: true,
    },
  },
  methods: {
    handleClose(done) {
      this.$emit("toggleShow", false);
      this.$refs['form'].clearValidate();
    },
    // 重置密码
    submit() {
      let param = {
        user_id: this.userInfo.id,
        password: this.form.password,
        password_check: this.form.repassword
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          resetPassword(param).then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message({
                type: "success",
                // message: "密码重置成功！",
                message: this.$t("dialog.userList.reset_pwd_success"),
                duration: 2000,
              });
              this.$emit("toggleShow", false);
            } else {
              this.$message({
                type: "warning",
                message: res.data.message,
                duration: 2000,
              });
              this.$emit("toggleShow", false);
            }
          });
        } else {
          return false;
        }
      });
      console.log(this.form, this.userInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
  .save {
    background-color: $main_color;
    border-color: $main_color;
  }
  .cancel {
    border-color: $main_color;
    background-color: #fff;
    color: $main_color;
    margin-left: 30px;
  }
}
.form {
  justify-content: center;
}
</style>