<template>
  <div class="order">
    <div class="key">
      <div>指令配置密钥：</div>
      <el-input
        v-model="key"
        placeholder="请输入内容"
        size="mini"
        class="input"
      ></el-input>
      <el-button type="success" size="mini" v-if="!openState" @click="open"
        >开启</el-button
      >
      <el-button type="danger" size="mini" v-else @click="off">关闭</el-button>
    </div>
    <div class="dialog" v-if="openState">
      <div class="backInfo">
        <!-- {{ backInfo }} -->
        <div v-text="backInfo"></div>
      </div>
      <div class="time">
        <div>等待在线时间：</div>
        <el-select v-model="time" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="textarea">
        <el-input
          type="textarea"
          placeholder="请输入指令"
          v-model="textarea"
          rows="6"
          resize="none"
        >
        </el-input>
      </div>
      <div class="btns">
        <el-button @click="send" size="mini" type="success" :disabled="disabled"
          >发送</el-button
        >
        <el-button @click="textarea = ''" size="mini" type="danger"
          >清除</el-button
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getVerify, sendCommand, closeCommand } from "@/api/index";
export default {
  name: "ReceiverAdminR51Order",

  data() {
    return {
      dialogShow: false,
      disabled: false,
      textarea: "",
      openState: false,
      key: "",
      backInfo: "",
      options: [
        {
          value: 1000,
          label: "1S",
        },
        {
          value: 2000,
          label: "2S",
        },
        {
          value: 3000,
          label: "3S",
        },
        {
          value: 5000,
          label: "5S",
        },
        {
          value: 10000,
          label: "10S",
        },
        {
          value: 15000,
          label: "15S",
        },
        {
          value: 20000,
          label: "20S",
        },
        {
          value: 30000,
          label: "30S",
        },
      ],
      time: 2000,
    };
  },

  activated() {
    if (this.openState) {
      this.$message.warning("正在关闭指令配置");
      this.off();
    }
  },
  deactivated() {
    // 离开页面默认关闭指令配置功能
    if (this.openState) {
      this.$message.warning("正在关闭指令配置");
      this.off();
    }
  },

  methods: {
    async send() {
      // this.formatCMD(this.textarea)
      let command = JSON.stringify(this.textarea);
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        command: this.formatCMD(this.textarea),
        // command: this.textarea.replaceAll("\n", "\r\n"),
        model: "2",
        len: command.length,
        wait: this.time / 1000,
      };
      this.disabled = true;
      setTimeout(() => {
          this.disabled = false;
        }, this.time + 1000);
      // console.log(JSON.stringify(this.textarea), this.time, "send");
      const res = await sendCommand(param);
      // console.log(res, "resss");
      if (res.code == 200) {
        this.backInfo = res.data;
      }
    },
    formatCMD(cmd) {
      let res = "";
      const endsWithNewline = /\n$/.test(cmd);
      // console.log(endsWithNewline, "endsWithNewline")
      if (endsWithNewline) {
        res = cmd.replaceAll("\n", "\r\n");
      } else {
        res = cmd.replaceAll("\n", "\r\n") + "\r\n";
      }
      return res;
    },
    async open() {
      // 开始配置
      let param = {
        sn: sessionStorage.getItem("currentSn"),
        commandPwd: this.key,
        model: "2",
      };
      let res = await getVerify(param);
      // console.log(res, "开始配置")
      if (res.code == 200) {
        this.$message.success("指令开启成功！");
        this.openState = true;
      }
    },
    async off() {
      // 停止配置
      let param = {
        sns: [sessionStorage.getItem("currentSn")],
        model: "2",
      };
      let res = await closeCommand(param);
      // console.log(res, "关闭配置")
      if (res.code == 200) {
        this.$message.success("指令关闭成功！");
        this.openState = false;
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.order {
  padding: 20px;
  height: 100%;
  .key {
    font-size: 16px;
    display: flex;
    // justify-content: flex-start;
    align-items: center;
  }
  .input {
    width: 20%;
    margin-right: 20px;
  }
  .dialog {
    height: 100%;
  }
  .backInfo {
    height: 50%;
    width: 80%;
    margin-top: 10px;
    border: 1px solid #eeeeee;
    overflow-x: hidden;
    white-space: pre-line; // 允许保留换行符
    word-break: break-all;
  }
  .time {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  .textarea {
    width: 80%;
    margin-bottom: 10px;
    // height: calc(100% - 80px - 20px);
  }
  //   .btns{

  //   }
}
</style>