<template>
  <div class="flex latlngFormart">
    <el-input v-for="item in Number(types)" type="number" @mousewheel.native.prevent :min="item === 1 ? (type === 'lat' ? -90 : -180) : 0.0000001"
      :max="item === 1 ? (type === 'lat' ? 90 : 180) : 59.999999" size="small" class="latlngFormart-input topbut botbut" :key="item"
      :placeholder="$t(`label.model.${lang[type]}`)" v-model="values[item]">
      <template slot="append">{{ unit[item] }}</template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "latlngFormart",
  props: {
    // 1 度 2 度分 3 度分秒
    types: {
      type: [String, Number],
      default: 1
    },
    value: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: 'lat'
    }
  },
  data() {
    return {
      unit: {
        1: "°",
        2: "′",
        3: "″"
      },
      values: {
        1: "",
        2: "",
        3: ""
      },
      lang: {
        lat: "latitude",
        lon: "longitude",
      }
    }
  },
  watch: {
    values: {
      handler(val) {
        let res = Object.keys(val).reduce((total, item) => {
          if (val[item]) {
            total.push(`${val[item]}${this.unit[item]}`)
          }
          return total;
        }, []).join("");
        if (res !== val && res !== '°′″') {
          if (this.types == 1) {
            res = parseFloat(res)
          }
          this.$emit("update:value", res)
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    setValue(val) {
      // if (val.length > 3) {
      if (val) {
        // 先转格式再切
        let _1 = val.indexOf("°");
        let _2 = val.indexOf("′");
        let _3 = val.indexOf("″");
        let res = {
          1: "",
          2: "",
          3: ""
        }
        if (_1 !== -1) {
          res[1] = val.slice(0, _1);
        } else {
          res[1] = val;
        }
        if (_2 !== -1) {
          // this.values[2] = val.slice(_1, _2);
          res[2] = val.slice(_1 + 1, _2);
        }
        if (_3 !== -1) {
          res[3] = val.slice(_2 + 1, _3);
        }
        this.values = res;
      } else {
        this.values = {
          1: "",
          2: "",
          3: ""
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.latlngFormart {
  gap: 0 8px;

  &-input {
    flex: 1;
  }

  ::v-deep .el-input-group__append {
    padding: 0 10px;
  }
}
/deep/  .topbut input::-webkit-outer-spin-button,
/deep/  .topbut input::-webkit-inner-spin-button {
        -webkit-appearance: none;
}
    /deep/  .topbut input[type="number"]{
        -moz-appearance: textfield;
    }
 
  /deep/  .botbut inpit{
        border: none
    }

</style>