<template>
  <div class="charts" id="charts">

  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    active: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: {
        tooltip: {},
        legend: {},
        xAxis: {
          data: [],
          axisLabel:{interval: 0}
          // boundaryGap:true
        },
        yAxis: {},
        series: [],
        // grid: {
        //   top: '12%',
        //   left: '5%',
        //   right: '5%',
        //   containLabel: true
        // },
      },
      dataZoom: {
        PC: 
        {
          type: 'slider',
          minValueSpan: 20,
        },
        // {
        //     type: 'inside',
        //     filterMode: 'filter',
        //     startValue: 0, // 从头开始。
        //     endValue: 18 // 最多5个
        //   },
        MOBIE: {
          type: 'slider',
          maxValueSpan: 8,
          minValueSpan: 8,
        }
      },
      echart: null,
    }
  },
  watch: {
    active: {
      handler(val) {
        if (val == 'chart') {
          this.$nextTick(() => {
            this.initCharts();
            this.handerData(this.tableData);
          })
        }
      },
      deep: true,
      // immediate: true
    },
    '$store.getters.deviceInfo': {
      handler(val) {
        this.$nextTick(() => {
          if (this.echart) {
            this.echart.resize();
            this.resetOption();
          }
        });
      },
      deep: true,
      immediate: true,
    },

  },
  mounted() {

  },
  methods: {
    initCharts() {
      if (!this.echart) {
        this.echart = echarts.init(
          document.getElementById("charts")
        );
      }

    },
    resetOption() {
      if (this.echart) {
        this.option.dataZoom = this.dataZoom[this.$store.getters.deviceInfo.type]
        this.echart.setOption(this.option);
      }
    },
    handerData(val) {
      if (!val.length) {
        return;
      }
      let xAxis_data = [];
      let b1L1 = {
        name: this.$t("label.satellite.SNR1"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      let b2L2 = {
        name: this.$t("label.satellite.SNR2"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      let b3L3 = {
        name: this.$t("label.satellite.SNR3"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      let b4L4 = {
        name: this.$t("label.satellite.SNR4"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      let b5L5 = {
        name: this.$t("label.satellite.SNR5"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      let b6L6 = {
        name: this.$t("label.satellite.SNR6"),
        type: "bar",
        barMaxWidth: "24",
        barWidth: "4",
        data: [],
        barGap: '10%',
      };
      val.forEach(item => {
        xAxis_data.push(`${item.label}${item.id}`);
        b1L1.data.push(item.SNR1)
        b2L2.data.push(item.SNR2)
        b3L3.data.push(item.SNR3)
        b4L4.data.push(item.SNR4)
        b5L5.data.push(item.SNR5)
        b6L6.data.push(item.SNR6)
      });
      this.option.series = [
        b1L1, b2L2, b3L3, b4L4, b5L5,b6L6
      ]
      this.option.xAxis.data = xAxis_data;
      this.resetOption();
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  height: 500px;
}
</style>