var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('custom-dialog',{attrs:{"show":_vm.show,"title":_vm.title,"width":"520"},on:{"toggleShow":_vm.handleClose}},[_c('div',{staticClass:"flex form",attrs:{"slot":"main"},slot:"main"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"inline":"","size":"small","label-width":_vm.$store.getters.deviceInfo.type != 'PC' ? 'auto' : '120px',"label-position":_vm.lang == 'ru-RU' || _vm.lang == 'tr-TR' || _vm.lang == 'es' ? 'top' : 'right'}},_vm._l((_vm.currentFormItem),function(item){return _c('el-form-item',{key:item.key,attrs:{"label":_vm.$t(item.label) + '：',"prop":item.key}},[(
            item.inputType == 'select' &&
            item.selectKey == 'option.userList.user_type_options'
          )?[_c('el-select',{staticStyle:{"width":"268px"},attrs:{"size":"small","placeholder":_vm.$t(item.label)},on:{"change":_vm.selectChange},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}},_vm._l((_vm.$t(item.selectKey)),function(option){return _c('el-option',{key:option.value,attrs:{"label":option.label,"value":option.value}})}),1)]:(
            item.inputType == 'select' &&
            item.selectKey == 'option.userList.dealer_options'
          )?[_c('el-select',{staticStyle:{"width":"268px"},attrs:{"size":"small","placeholder":_vm.$t(item.label)},on:{"change":_vm.selectChange2},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}},_vm._l((_vm.options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.account,"value":option.id}})}),1)]:(item.inputType == 'cascader')?[_c('el-cascader',{staticStyle:{"width":"268px"},attrs:{"size":"small","placeholder":_vm.$t(item.label),"options":_vm.belong_options},on:{"change":_vm.testCHange},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}})]:(item.inputType == 'input')?[(item.key == 'elevation')?[_c('div',{staticClass:"flex"},[_c('el-input',{attrs:{"placeholder":_vm.$t(item.label),"maxlength":"10"},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}}),_c('span',{staticStyle:{"margin":"0 12px"}},[_vm._v("m")]),_c('el-button',{staticClass:"btn save",attrs:{"type":"primary","size":"small"}},[_vm._v(" "+_vm._s(_vm.$t("label.model.getLonlat"))+" ")])],1)]:[_c('el-input',{staticStyle:{"width":"268px"},attrs:{"size":"small","show-password":item.inputModel == 'pwd',"maxlength":item.inputModel == 'pwd' || item.inputModel == 'tel'
                  ? '15'
                  : '10',"placeholder":_vm.$t(item.label)},on:{"input":function($event){return _vm.change($event)}},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}})]]:_vm._e()],2)}),1)],1),_c('div',{staticClass:"flex btns",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"btn cancel",attrs:{"type":"success","size":"small"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t("dialog.userList.cancel"))+" ")]),_c('el-button',{staticClass:"btn save",attrs:{"type":"primary","size":"small"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t("dialog.userList.submit"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }