<template>
  <div class="updata-item" :class="[deviceInfo.type]">
    <div class="updata-item-title">
      {{ title }}
    </div>
    <div class="flex updata-item-cell">
      <div class="updata-item-cell-upload">
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
          :file-list="fileList"
          :http-request="beforeUpload"
          :title="fileList[0] ? fileList[0].name : ''"
        >
          <el-button size="small" class="btn" type="primary">{{
            $t("label.updata.updata_text")
          }}</el-button>
          <p class="noFile" v-if="!fileList.length">
            {{ $t("message.updata.noFile") }}
          </p>
        </el-upload>
      </div>
      <el-button
        class="btn"
        size="small"
        type="primary"
        @click="updataFirmware()"
        >{{ $t("label.updata.firmware_updata") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { receiverFileUploads } from "@/api/index";
export default {
  name: "UpdataCell",
  props: {
    title: {
      type: String,
      default: "",
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    deviceInfo() {
      return this.$store.getters.deviceInfo;
    },
  },
  watch: {
    files: {
      handler(val) {
        this.fileList = val;
      },
      deep: true,
    },
  },
  deactivated() {
    this.fileList = []; //切换时候清除缓存
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = [];
      this.$emit("getIndex", this.index);
      this.$emit("getPath", "");
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(file);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    updataFirmware() {
      // if (!this.fileList.length) {
      // 	this.$message.warning(this.$t("message.updata.noFile"));
      // 	return;
      // }
      this.$emit("updataFirmware");
    },
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
      console.log(response, file, fileList);
    },
    // 上传文件
    beforeUpload(file) {
      console.log(123, file.file.size);
      if (file.file.size / 1024 / 1024 > 20) {
        this.$message({
          type: "warning",
          // message: '文件大小不能超过20M,请重新上传！'
          message: this.$t("label.updata.upload_warning_info"),
          duration: 2000,
        });
        this.handleRemove();
        return;
      } else {
        // let param = {
        // 	file:file,
        // 	fileName:file.name
        // }
        console.log("2222", this.index);
        let formData = new FormData();
        formData.append("file", file.file);
        // formData.append("fileName", file.file.name);
        // console.log(this.fileList);
        receiverFileUploads(formData).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$emit("getIndex", this.index);
            // this.$emit("getPath", res.data.path);
            this.$emit("getPath", file.file.name);
            this.$message({
              type: "success",
              // message: '文件上传成功!',
              message: this.$t("label.updata.upload_success_info"),
              duration: 2000,
            });
            this.fileList.push(file.file);
          } else {
            this.$message({
              type: "error",
              message: res.msg,
              duration: 2000,
            });
          }
          // this.$emit('updataFirmware',res.data.path)
          // this.path = res.data.path
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.updata-item {
  & + .updata-item {
    margin-top: 20px;
  }
  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 36px;
  }
  .btn {
    background-color: $main_color;
    border-color: $main_color;
  }
  &::v-deep .upload-demo {
    display: flex;
    align-items: center;
    max-width: 380px;
  }
  &::v-deep .el-upload {
    position: relative;
  }
  &::v-deep .el-upload-list {
    flex: 1;
  }
  .updata-item-cell-upload {
    flex: 1;
    max-width: 450px;
    position: relative;
    .noFile {
      position: absolute;
      word-break: keep-all;
      white-space: nowrap;
      left: calc(100% + 20px);
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #333333;
    }
  }
}
.MOBIE {
  .updata-item-cell {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #539d56;
  }
}
::v-deep {
  .el-upload-list__item-name {
    max-width: 300px;
  }
  .el-upload-list__item.is-success.focusing .el-icon-close-tip {
    display: none !important;
  }
}
</style>
<style lang="scss">
  .el-message-box__message p{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
    // word-wrap: break-word !important; // 换行显示
  }
</style>